import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Chart from 'react-apexcharts';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.min.css';

function AvailableDataBarChart({ seriesData, labels }) {
  const navigate = useNavigate();
  const series = [
    {
      name: 'series-1',
      data: seriesData,
    },
  ];

  const options = {
    chart: {
      id: 'basic-bar',
      nonce: document
        .querySelector('meta[name="csp-nonce"]')
        .getAttribute('content'),
      events: {
        click: (event, chartContext, config) => {
          const categories = config.config.xaxis.categories;
          const index = config.dataPointIndex;
          if (index === -1) {
          } else {
            const type = categories[index].toLowerCase();
            if (type === 'audio' || type === 'video') {
              Swal.fire({
                title: 'Privacy',
                icon: 'warning',
                text: 'Audio and Video data is not being shown due to privacy concern',
                confirmButtonColor: 'var(--primary-color)',
              });
            } else if (type === 'd3' || type === 'd4' || type === 'd5') {
              Swal.fire({
                title: 'Information',
                icon: 'info',
                text: 'Information about PARADIGMS',
                confirmButtonColor: 'var(--primary-color)',
              });
            } else if (
              type === 'eeg' ||
              type === 'eog' ||
              type === 'ecg' ||
              type === 'gsr' ||
              type === 'eye_gaze'
            ) {
              navigate('/dataDetails', { state: { type: type } });
              // console.log(type);
            } else {
            }
          }
        },
      },
    },
    xaxis: {
      categories: labels,
      title: {
        text: 'modalities',
      },
    },
    yaxis: {
      title: {
        text: 'counts',
      },
    },
    colors: ['#b86b12'],
    theme: {
      monochrome: {
        enabled: true,
        color: '#b86b12',
        shadeTo: 'light',
      },
    },
    responsive: [
      {
        breakpoint: 1000,

        options: {
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          legend: {
            position: 'bottom',
          },
        },
      },
      {
        breakpoint: 480,
        options: {
          plotOptions: {
            bar: {
              columnWidth: '50%',
            },
          },
          xaxis: {
            labels: {
              show: false,
            },
          },
        },
      },
    ],
  };

  return (
    <Chart
      type="bar"
      series={series}
      options={options}
      width={'100%'}
      height={300}
    />
  );
}

export default AvailableDataBarChart;
