import React, { useEffect, useState } from 'react';
// import { papers } from "./PaperDetails";
import './Research.css';
import ResearchCard from './ResearchCard';
import Heading from '../Heading';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

function Research() {
  const [papers, setPapers] = useState([]);
  const axios = useAxiosPrivate();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  useEffect(() => {
    fetchPapers();
  }, []);

  const fetchPapers = async () => {
    try {
      const response = await axios.get(`/user/research-papers`);
      // console.log(response.data);
      setPapers(response.data);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className="rnd">
      <div className="rnd-container">
        <Heading text={'Research Papers'} />
        <div className="research-papers">
          {papers.map((item, index) => (
            <ResearchCard
              key={index}
              title={item.title}
              description={item.detail}
              link={item.link}
              authors={item.authors}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Research;
