import React, { useState, useContext } from 'react';
import Heading from '../components/Heading';
import Button from '../components/Button';
import { ScrollContext } from '../context/ScrollContext';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAuth from '../hooks/useAuth';
import Tost from '../components/Tost/Tost';
import Loader from '../components/Loader/Loader';
import NavBar from '../components/NavBar';
import LogoBar from '../components/LogoBar';
import { validateCustomizeOrder } from '../Utils/FormValidation';
import './CustomizeOrder.css';

function CustomizeOrder({ navigate }) {
  const { skipref } = useContext(ScrollContext);
  const { auth, setAuth } = useAuth();
  const [tostMessage, setTostMessage] = useState('Oops Something went wrong');
  const [tostType, setTostType] = useState('error');
  const [openT, setOpenT] = useState(false);
  const [loader, setLoader] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [description, setDescription] = useState('');
  const [subCount, setSubCount] = useState(0);
  const axios = useAxiosPrivate();

  // Function to handle checkbox change
  const handleCheckboxChange = (option) => {
    // Check if the option is already selected
    const selectedIndex = selectedOptions.indexOf(option);

    if (selectedIndex === -1) {
      // If not selected, add it to the array
      setSelectedOptions([...selectedOptions, option]);
    } else {
      // If selected, remove it from the array
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    }
  };

  const handleCustomizeOrder = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);

      const { status, msg } = validateCustomizeOrder(description, subCount);
      if (!status) {
        setLoader(false);
        setOpenT(true);
        setTostMessage(msg);
        setTostType('error');
        return;
      }
      const requestData = {
        paradigm_description: description,
        modalities: selectedOptions.join(', '),
        subject_count: subCount,
        email: auth.email,
      };
      const response = await axios.post(`/user/customize-order`, requestData);
      setLoader(false);
      setTimeout(() => {
        setOpenT(false);
      }, 2000);
      setOpenT(true);
      setTostMessage(response.data.status);
      setTostType('success');

      setDescription('');
      setSelectedOptions([]);
      setSubCount(0);
    } catch (e) {
      setLoader(false);
      setTimeout(() => {
        setOpenT(false);
      }, 2000);

      if (e.code === 'ERR_NETWORK') {
        setTostMessage(e.message);
      } else {
        const errorMessage = e.response?.data?.detail;
        if (typeof errorMessage === 'string') {
          setTostMessage(errorMessage);
        } else {
          setTostMessage('An error occurred while processing your request');
        }
      }
      setTostType('error');
      setOpenT(true);
    }
  };

  return (
    <>
      <NavBar navigate={navigate} type={'dataEcho'} />
      <LogoBar navigate={navigate} type={'dataEcho'} />
      <form autoComplete="off" onSubmit={handleCustomizeOrder}>
        <div className="CustomizeOrder-container" ref={skipref}>
          <div className="CustomizeOrder-form-wrapper">
            <Heading
              text={
                'The Art of Data Customization: Crafting Your Perfect Dataset on Demand'
              }
            />
            <span className="CustomizeOrder-description-text">
              Researchers have the flexibility to customize their dataset orders
              based on specific requirements. Whether it's selecting particular
              modalities, specifying annotation details, or requesting tailored
              datasets for their studies, our platform enables users to place
              custom orders to suit their research needs.
            </span>
            <Heading text={' Paradigm Description'} />
            <textarea
              autoComplete="off"
              className="CustomizeOrder-textarea"
              placeholder="Type here paradigm description"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              required
            />

            <div className="CustomizeOrder-modalities-wrapper">
              <Heading text={'Select Modalities & Subject Counts'} />
              <div className="CustomizeOrder-modalities-options">
                <label>
                  <input
                    autoComplete="off"
                    className="CustomizeOrder-checkbox-label"
                    type="checkbox"
                    value=" EEG"
                    checked={selectedOptions.includes(' EEG')}
                    onChange={() => handleCheckboxChange(' EEG')}
                  />
                  EEG
                </label>

                <label>
                  <input
                    autoComplete="off"
                    className="CustomizeOrder-checkbox-label"
                    type="checkbox"
                    value=" ECG"
                    checked={selectedOptions.includes(' ECG')}
                    onChange={() => handleCheckboxChange(' ECG')}
                  />
                  ECG
                </label>

                <label>
                  <input
                    autoComplete="off"
                    className="CustomizeOrder-checkbox-label"
                    type="checkbox"
                    value=" EOG"
                    checked={selectedOptions.includes(' EOG')}
                    onChange={() => handleCheckboxChange(' EOG')}
                  />
                  EOG
                </label>

                <label>
                  <input
                    autoComplete="off"
                    className="CustomizeOrder-checkbox-label"
                    type="checkbox"
                    value=" GSR"
                    checked={selectedOptions.includes(' GSR')}
                    onChange={() => handleCheckboxChange(' GSR')}
                  />
                  GSR
                </label>

                <label>
                  <input
                    autoComplete="off"
                    className="CustomizeOrder-checkbox-label"
                    type="checkbox"
                    value=" AUDIOVIDEO"
                    checked={selectedOptions.includes(' AUDIOVIDEO')}
                    onChange={() => handleCheckboxChange(' AUDIOVIDEO')}
                  />
                  AUDIO & VIDEO
                </label>

                <label className="CustomizeOrder-subject-count-label">
                  Subjects Counts
                  <input
                    autoComplete="off"
                    type="number"
                    value={subCount}
                    onChange={(e) => setSubCount(e.target.value)}
                    required
                  />
                </label>
              </div>
            </div>

            <div className="CustomizeOrder-request-button-wrapper">
              <Button text={'Request'} />
            </div>
          </div>
          <Tost
            message={tostMessage}
            openTost={openT}
            setOpenTost={setOpenT}
            type={tostType}
          />
          <Loader loader={loader} setLoader={setLoader} />
        </div>
      </form>
    </>
  );
}

export default CustomizeOrder;
