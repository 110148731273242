import React from "react";
import NavBar from "../components/NavBar";
import LogoBar from "../components/LogoBar";
import Heading from "../components/Heading";
import "./PrivacyPolicy.css"; // Import the CSS file

function PrivacyPolicy({ navigate }) {
  return (
    <>
      <NavBar navigate={navigate} type={"landing"} />
      <LogoBar navigate={navigate} type={"landing"} />
      <div className="PrivacyPolicy-container">
        <div className="PrivacyPolicy-content">
          <Heading text={"Privacy Policy"} />
          <p>
            This Privacy Policy governs the manner in which this website
            collects, uses, maintains, and discloses data.
          </p>
          <p>
            The images displayed on our website are AI generated and are
            copyright free.
          </p>
          <p>
            This website displays pictures and data of individuals from whom we
            have obtained consent. This website does not automatically capture
            any specific personal information from you, (like name, phone number
            or e-mail address), that allows us to identify you individually. If
            website requests you to provide personal information, you will be
            informed for the particular purposes for which the information is
            gathered and adequate security measures will be taken to protect
            your personal information.
          </p>
          <p>
            We do not sell or share any personally identifiable information
            volunteered on this website to any third party (public/private). Any
            information provided to this website will be protected from loss,
            misuse, unauthorized access or disclosure, alteration, or
            destruction.
          </p>
          <p>
            We respect copyright laws and intellectual property rights and will
            promptly respond to any claims of copyright infringement reported to
            us.
          </p>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
