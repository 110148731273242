import React, { useState, useContext, useRef } from 'react';
import ContactUs from '../components/ContactUs';
import Services from '../components/Services';
import FrontSection from '../components/FrontSection';
import Activities from '../components/Activities';
import { ScrollContext } from '../context/ScrollContext';
import Loader from '../components/Loader/Loader';
import NavBar from '../components/NavBar';
import LogoBar from '../components/LogoBar';
import LoaderComp from '../components/LoaderComp/LoaderComp';
import './Home.css';

function Home({ navigate }) {
  const { skipref } = useContext(ScrollContext);
  return (
    <>
      <NavBar navigate={navigate} type={'dataEcho'} />
      <LogoBar navigate={navigate} type={'dataEcho'} />
      <div className="Home-container">
        <div className="Home-inner-container">
          <FrontSection ref={skipref} />
          <Services ref={skipref} />
          <Activities />
          <ContactUs type={'dataEcho'} />
        </div>
      </div>
    </>
  );
}

export default Home;
