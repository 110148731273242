import React from "react";
import Heading from "../../components/Heading";
import Button from "../../components/Button";
import "./About.css"; // Import the CSS file
import PDF from "../../PFD/MNP Brochure (A4).pdf";

function AboutProject() {
  const downloadPdf = () => {
    const a = document.createElement("a");
    a.href = PDF;
    a.download = "2024-03-10_Neuro-Cognitive AI - Data Echo_Brochure.pdf";
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div className="AboutProject-AboutContainer">
      <Heading text={"About Data Echo"} />
      <p className="AboutProject-Paragraph">
        The Data-Echo Platform provides high-density A Multi-Modal
        Neuro-Physiological data of EEG, EoG, ECG, GSR, Eye Gaze, Audio, and
        Video data along with time synchronized bio-markers on “deception
        detection” paradigm recorded for 33 minutes with substantial sample
        size. This is the outcome of the MeitY funded project "As an outcome of
        the project "A Multi-Modal Neuro-Physiological Framework for Behaviour
        Analysis
      </p>
      <div className="AboutProject-ButtonContainer">
        <Button text={"Download Brochure"} onClick={downloadPdf} />
      </div>
    </div>
  );
}

export default AboutProject;
