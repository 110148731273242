import React from 'react';
import './Styles/Input.css'; // Import the CSS file

function Input({ label, type, onChange, name, value, placeholder, inputType }) {
  if (type === 'textarea') {
    return (
      <div>
        <div className="Input-container">
          <span className="Input-label">{label}</span>
          <textarea
            autoComplete="off"
            aria-autocomplete="none"
            className="Input-textarea"
            placeholder="Write your query here."
            rows={5}
            onChange={onChange}
            value={value}
            name={name}
            required
          />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="Input-container">
          <span className="Input-label">{label}</span>
          <input
            autoComplete="off"
            aria-autocomplete="none"
            className="Input-input"
            type={inputType ? inputType : 'text'}
            onChange={onChange}
            value={value}
            name={name}
            placeholder={placeholder}
            required
          />
        </div>
      </div>
    );
  }
}

export default Input;
