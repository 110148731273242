import React, { useRef } from "react";
import { ScrollContext } from "./ScrollContext";

function ScrollState({ children }) {
  const serviceref = useRef(null);
  const skipref = useRef(null);

  const scrollIntoDiv = () => {
    if (serviceref.current) {
      serviceref.current.scrollIntoView({ behavior: "smooth" });
    }

    if (skipref.current) {
      skipref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <ScrollContext.Provider value={{ scrollIntoDiv, skipref, serviceref }}>
      {children}
    </ScrollContext.Provider>
  );
}

export default ScrollState;
