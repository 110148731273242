import React from "react";
import MeteorBackground from "./MeteorBackground";
import "./Styles/FrontSection.css"; // Import the CSS file

function FrontSection(ref) {
  return (
    <MeteorBackground ref={ref}>
      <div className="FrontSection-container">
        <div className="FrontSection-top-container">
          <h1 className="FrontSection-heading1">Elevating Intelligence...</h1>
          <h3 className="FrontSection-heading3">
            Echoing anecdotes for Exhibiting Data Mysteries and Experiencing
            Multimodal Mastery
          </h3>
          <div>
            <p className="FrontSection-para">
              The Data-Echo Platform provides Multi-Modal Neuro-Physiological
              data of EEG, EoG, ECG, GSR, Eye Gaze, Audio and Video data. With
              the objective of “Deception Detection”, this high-density data is
              enriched with time synchronized bio-markers on various paradigms
              recorded on more than 100 subjects curated for digital forensic
              domain. The project "A Multi-Modal Neuro-Physiological Framework
              for Behaviour Analysis" is supported by MeitY, Govt of India.
            </p>
          </div>
        </div>
      </div>
    </MeteorBackground>
  );
}

export default FrontSection;
