import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Subject1 from '../images/Subject1.jpeg';
import Subject2 from '../images/Subject2.jpeg';
import Subject3 from '../images/Subject3.jpeg';
import Subject5 from '../images/Subject5.jpg';
import Subject6 from '../images/Subject6.jpg';
import Subject7 from '../images/Subject7.jpg';

const SubjectCarousel = () => {
  return (
    <Carousel
      // width={'320px'}
      showThumbs={false}
      showArrows={false}
      showStatus={false}
      autoPlay
      interval={2000}
      infiniteLoop
      showIndicators={false}
    >
      <img src={Subject1} />
      <img src={Subject2} />
      <img src={Subject3} />
      <img src={Subject5} />
      <img src={Subject6} />
      <img src={Subject7} />
    </Carousel>
  );
};

export default SubjectCarousel;
