import React, { useState, useContext } from 'react';
import Modal from './Modal';
import Heading from './Heading';
import Button from './Button';
import './Styles/FeedbackModal.css'; // Import the CSS file
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import Loader from '../components/Loader/Loader';
import Tost from '../components/Tost/Tost';
import { AuthContext } from '../context/AuthContext';
import { validateFeedback } from '../Utils/FormValidation';

function FeedbackModal({
  open,
  handleClose,
  handleCheckboxChange,
  selectedOptions,
  setSelectedOptions,
}) {
  const [purpose, setPurpose] = useState('');
  const [subCount, setSubCount] = useState(0);
  const [tostOpen, setTostOpen] = useState(false);
  const [tostMessage, setTostmessage] = useState('Oops Something went wrong');
  const [tostType, setTostType] = useState('error');
  const [loader, setLoader] = useState(false);
  const { auth } = useContext(AuthContext);
  const axios = useAxiosPrivate();

  const handleFeedbackSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoader(true);

      const { status, msg } = validateFeedback(purpose, subCount);
      if (!status) {
        setLoader(false);
        setTostOpen(true);
        setTostmessage(msg);
        setTostType('error');
        return;
      }
      const modalities = selectedOptions.join(', ');
      const request = {
        email: auth.email,
        modalities: modalities,
        filters: 'Age, Paradigm',
        purpose: purpose,
        data_count: subCount,
      };

      const response = await axios.post(`/access/feedback`, request);

      setLoader(false);
      setPurpose('');
      setSubCount(0);
      setSelectedOptions([]);

      setTimeout(() => {
        setTostOpen(false);
        handleClose();
      }, 3000);
      setTostOpen(true);
      setTostmessage(response.data.status);
      setTostType('success');
    } catch (e) {
      // console.log(e);
      setLoader(false);
      setTimeout(() => {
        setTostOpen(false);
      }, 5000);
      if (e.code === 'ERR_NETWORK') {
        setTostmessage(e.message);
      } else {
        setTostmessage(e.response.data.detail);
      }
      setTostType('error');
    }
  };

  return (
    <Modal isOpen={open} width={'80%'} handleClose={handleClose}>
      <form
        autoComplete="off"
        className="FeedbackModal-form-container"
        onSubmit={handleFeedbackSubmit}
      >
        <div>
          <Heading text={'Multimodality'} />
          <div className="FeedbackModal-checkbox-container">
            <label className="FeedbackModal-label">
              <input
                autoComplete="off"
                className="FeedbackModal-checkbox"
                type="checkbox"
                value=" EEG"
                checked={selectedOptions.includes(' EEG')}
                onChange={() => handleCheckboxChange(' EEG')}
              />
              EEG
            </label>
            <br />
            <label className="FeedbackModal-label">
              <input
                autoComplete="off"
                className="FeedbackModal-checkbox"
                type="checkbox"
                value="ECG"
                checked={selectedOptions.includes('ECG')}
                onChange={() => handleCheckboxChange('ECG')}
              />
              ECG
            </label>
            <br />
            <label className="FeedbackModal-label">
              <input
                autoComplete="off"
                className="FeedbackModal-checkbox"
                type="checkbox"
                value="EOG"
                checked={selectedOptions.includes('EOG')}
                onChange={() => handleCheckboxChange('EOG')}
              />
              EOG
            </label>
            <br />
            <label className="FeedbackModal-label">
              <input
                autoComplete="off"
                className="FeedbackModal-checkbox"
                type="checkbox"
                value="GSR"
                checked={selectedOptions.includes('GSR')}
                onChange={() => handleCheckboxChange('GSR')}
              />
              GSR
            </label>
            <br />

            <label className="FeedbackModal-label">
              <input
                autoComplete="off"
                className="FeedbackModal-checkbox"
                type="checkbox"
                value="AUDIO&VIDEO"
                checked={selectedOptions.includes('AUDIO&VIDEO')}
                onChange={() => handleCheckboxChange('AUDIO&VIDEO')}
              />
              AUDIO & VIDEO
            </label>

            <label className="FeedbackModal-subjects-label">
              Subjects Counts
              <input
                autoComplete="off"
                className="FeedbackModal-text-input"
                type="number"
                onChange={(e) => setSubCount(e.target.value)}
                value={subCount}
                required
              />
            </label>
          </div>

          <div className="FeedbackModal-form-container">
            <Heading text={'Purpose'} />
            <textarea
              autoComplete="off"
              className="FeedbackModal-textarea"
              placeholder="type here..."
              onChange={(e) => setPurpose(e.target.value)}
              value={purpose}
              required
            />
          </div>
          <div className="FeedbackModal-button-container">
            <Button text={'Subscribe'} />
          </div>
        </div>

        <Tost
          message={tostMessage}
          openTost={tostOpen}
          setOpenTost={setTostOpen}
          type={tostType}
        />
        <Loader loader={loader} setLoader={setLoader} />
      </form>
    </Modal>
  );
}

export default FeedbackModal;
