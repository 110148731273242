import React from "react";
import Modal from "./Modal";
import Button from "./Button";
import "./Styles/FilterModal.css"; // Import the CSS file

function FilterModal({ isOpen, handleClose, setType }) {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <div className="FilterModal-modal-content">
        <span className="FilterModal-modal-title">Select Type</span>
        <div className="FilterModal-filter-container">
          <span
            className="FilterModal-span"
            onClick={() => {
              setType("demographic");
              handleClose();
            }}
          >
            Demography
          </span>
          <span
            className="FilterModal-span"
            onClick={() => {
              setType("modality");
              handleClose();
            }}
          >
            Modality
          </span>
          <span
            className="FilterModal-span"
            onClick={() => {
              setType("paradigm");
              handleClose();
            }}
          >
            Paradigm
          </span>
          <span
            className="FilterModal-span"
            onClick={() => {
              setType("modality");
              handleClose();
            }}
          >
            Multimodality
          </span>
          <span
            className="FilterModal-span"
            onClick={() => {
              setType("age");
              handleClose();
            }}
          >
            Age
          </span>
        </div>
      </div>
    </Modal>
  );
}

export default FilterModal;
