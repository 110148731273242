import React from 'react';
import AboutProject from './AboutProject';
import AboutCdac from './AboutCdac';
import './About.css';

function About() {
  return (
    <>
      <div className="about-container">
        {/* Bootstrap */}

        <div className="about-content-container">
          <nav>
            <div className="nav nav-tabs flex-row1" id="nav-tab" role="tablist">
              <button
                className="nav-link active"
                id="nav-home-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-home"
                type="button"
                role="tab"
                aria-controls="nav-home"
                aria-selected="true"
              >
                About Project
              </button>
              <button
                className="nav-link"
                id="nav-profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-profile"
                type="button"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="false"
              >
                About Cdac
              </button>
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
              tabindex="0"
            >
              <AboutProject />
            </div>
            <div
              className="tab-pane fade"
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
              tabindex="0"
            >
              <AboutCdac />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
