import React, { useContext, useEffect } from 'react';
import NavBar from '../components/NavBar';
import LogoBar from '../components/LogoBar';
import CdacLogo from '../images/text_logo.png';
import Meity from '../images/meity_black.png';
import ContactUs from '../components/ContactUs';
import { ScrollContext } from '../context/ScrollContext';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import './LandingPage.css';

function LandingPage({ navigate }) {
  const { skipref } = useContext(ScrollContext);
  const axios = useAxiosPrivate();

  useEffect(() => {
    hitCount();
  }, []);

  const hitCount = async () => {
    try {
      await axios.post('/auth/track-hit');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <NavBar navigate={navigate} type={'landing'} />
      <LogoBar navigate={navigate} type={'landing'} />

      <div className="LandingPage-main-container" ref={skipref}>
        <div className="LandingPage-content-container">
          <div className="LandingPage-styled-div">
            <img src={Meity} width={'300px'} alt="meity logo" />
            <h2 className="LandingPage-heading">
              Neuro-Cognitive AI & XR Group
            </h2>

            <img
              src={CdacLogo}
              // height={'150px'}
              width={'150px'}
              alt="Cdac logo"
            />
          </div>
          <div className="LandingPage-text-content mt-5">
            <p>
              Neuro-Cognitive AI & XR Group, C-DAC Delhi started in 2021 with
              the primarily focuses on AI driven Neuro-Cognitive Brain
              Behavioural Computing. Presently, the group is deeply engaged in
              significant projects and extended research revolving around the
              humankind. The research projects financially backed by Government
              of India and also thrive on collaborations with esteemed
              government organizations across the nation.
            </p>
            <p>
              Furthermore, the group has established a state-of-the-art
              laboratory that is equipped with advanced technology for the
              acquisition and analysis of brain signals and physiological data.
              This specialized group applies its efforts on the development of
              assistive learning and rehabilitation technologies, by harnessing
              an array of cutting-edge tools such as signal processing, Natural
              Language Processing (NLP), Computer Vision (CV), Augmented Reality
              (AR), Virtual Reality (VR), High-Performance Computing (HPC), and
              research in Quantum Computing as well.
            </p>
            <p>
              The focal point in healthcare domain remains on applications
              encompassing screening, tracking response to diagnosis, predictive
              treatment modelling, and rehabilitation strategies for conditions.
              The group takes pride in its expertise across domains including
              assistive technology, fMRI & PET Brain Imaging analysis, Cognitive
              Behavioural Therapy (CBT), Explainable AI (XAI), Micro-expression
              behavioural Analysis, and psychological forensic analysis. This
              collaborative effort harnesses expertise in areas including
              digital pathology, digital forensic analysis, Brain-Computer
              Interface (BCI) development, multimodal AI, and metaverse-based
              solutions, etc.
            </p>
          </div>
        </div>
      </div>
      <ContactUs type={'landing'} />
    </>
  );
}

export default LandingPage;
