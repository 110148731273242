import React, { useContext } from "react";
import { FaLaptop } from "react-icons/fa";
import { ScrollContext } from "../context/ScrollContext";
import "./Styles/AccessbilityBar.css"; // Import the CSS file

function AccessbilityBar() {
  const { scrollIntoDiv } = useContext(ScrollContext);
  return (
    <div className="AccessbilityBar-styled-top-div">
      <div className="AccessbilityBar-styled-div">
        <div>
          <div className="AccessbilityBar-content-div">
            <FaLaptop />
            <span className="AccessbilityBar-skip-link" onClick={scrollIntoDiv}>
              Skip to main content
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccessbilityBar;
