import React, { useState } from "react";
import "./ResearchCard.css";
import "./ResearchCard.css";

function ResearchCard({ title, description, authors, link }) {
  const [show, setShow] = useState(false);
  const downloadPdf = () => {
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div
      className="research-card ResearchCard-card"
      onClick={() => window.open(link)}
    >
      <div className="ResearchCard-title">{title}</div>
      <div className="ResearchCard-authors">{authors}</div>
      <div className="ResearchCard-description">{description}</div>
      <div className="ResearchCard-styledDiv"></div>
    </div>
  );
}

export default ResearchCard;
