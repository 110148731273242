import MINT from '../../images/news-mint.jpg';

export const newsList = [
  {
    heading: 'Can brain-tech treat paralysis, mental illness?',
    date: '2023 September 22',
    news: `Neuralink, Elon Musk's brain-tech startup, is looking to launch clinical trials
    to put brain implants that would enable patients with deep neural disabilities,
    such as paralysis, to discharge normal functions such as reading and speech. Is this sci-fi? Ap 
    The Centre for Development of
    Advanced Computing (C-DAC) is
    building BCIS to capture impulses
    of intentions from the brain. This
    project is at a prototype stage. It
    is being tested by the All India
    Institute of Medical Sciences.
    Startup BrainSight AI is working
    on (connectomics'—studying
    neural links to create a brain map
    and understand the reasons
    behind ailments.`,
    image: MINT,
    siteLink:
      'https://www.pressreader.com/india/mint-hyderabad/20230922/page/3/textview',
  },
];
