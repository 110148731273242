import React, { useState } from 'react';
import './ChannelList.css';

const channels = {
  eeg: [
    'Fp1',
    'Fz',
    'F3',
    'F7',
    'FT9',
    'FC5',
    'FC1',
    'C3',
    'CP5',
    'CP1',
    'Pz',
    'P3',
    'P7',
    'O1',
    'Oz',
    'O2',
    'P4',
    'P8',
    'CP6',
    'CP2',
    'Cz',
    'C4',
    'FT10',
    'FC6',
    'FC2',
    'F4',
    'F8',
    'FP2',
  ],
  eog: ['EOGL', 'EOGR'],
  ecg: ['ECGL', 'ECGR'],
  gsr: ['Galvanic skin response (GSR)_1'],
  eye_gaze: [
    'Gaze point X_1',
    'Gaze point Y_1',
    'Gaze point left X_1',
    'Gaze point left Y_1',
    'Gaze point right X_1',
    'Gaze point right Y_1',
    'Gaze direction left X_1',
    'Gaze direction left Y_1',
    'Gaze direction left Z_1',
    'Gaze direction right X_1',
    'Gaze direction right Y_1',
    'Gaze direction right Z_1',
    'Pupil diameter left_1',
    'Pupil diameter right_1',
    'Pupil diameter filtered_1',
    'Eye position left X (DACSmm)_1',
    'Eye position left Y (DACSmm)_1',
    'Eye position left Z (DACSmm)_1',
    'Eye position right X (DACSmm)_1',
    'Eye position right Y (DACSmm)_1',
    'Eye position right Z (DACSmm)_1',
    'Gaze point left X (DACSmm)_1',
    'Gaze point left Y (DACSmm)_1',
    'Gaze point right X (DACSmm)_1',
    'Gaze point right Y (DACSmm)_1',
    'Gaze point X (MCSnorm)_1',
    'Gaze point Y (MCSnorm)_1',
    'Gaze point left X (MCSnorm)_1',
    'Gaze point left Y (MCSnorm)_1',
    'Gaze point right X (MCSnorm)_1',
    'Gaze point right Y (MCSnorm)_1',
    'Time_stamps',
  ],
};

function ChannelList({ selected, setSelected, type }) {
  return (
    <div className="channelList-container">
      {channels[type].map((item, index) => (
        <span
          className={`channelList-styled-span ${
            selected === index ? 'selected' : ''
          }`}
          key={index}
          onClick={() => setSelected(index)}
        >
          {item}
        </span>
      ))}
    </div>
  );
}

export default ChannelList;
