import React, { useState } from 'react';
import Input from './Input';
import CONTACT from '../images/Contact.png';
import groupLogo from '../images/group-logo2.png';
import Button from './Button';
import emailjs from 'emailjs-com';
import Tost from '../components/Tost/Tost';
import Loader from '../components/Loader/Loader';
import { validateConnectUs } from '../Utils/FormValidation';
import './Styles/ContactUs.css'; // Import the CSS file
import ReCAPTCHA from 'react-google-recaptcha';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

function ContactUs({ type }) {
  const [formData, setFormData] = useState({
    from_name: '',
    from_email: '',
    message: '',
    to_name: 'DataEcho Team',
  });
  const [templateId] = useState(process.env.REACT_APP_TEMPLATE_ID);
  const [ServiceID] = useState(process.env.REACT_APP_SERVICE_ID);
  const [userId] = useState(process.env.REACT_APP_USER_ID);

  const axios = useAxiosPrivate();

  const [loader, setLoader] = useState(false);
  const [openTost, setOpenTost] = useState(false);
  const [tostMessage, setTostMessage] = useState('Oops something went wrong!!');
  const [tostType, setTostType] = useState('error');
  const [captchaStr, setCaptchaStr] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   setLoader(true);

  //   const { status, msg } = validateConnectUs(formData);
  //   if (!status) {
  //     setLoader(false);
  //     setOpenTost(true);
  //     setTostMessage(msg);
  //     setTostType('error');
  //     return;
  //   }
  //   emailjs
  //     .sendForm('service_9glwdwd', templateId, e.target, 'skMbGFMqTDIwSB0JH')
  //     .then(
  //       (result) => {
  //         // Handle successful email sending, e.g., show success message, clear form, etc.
  //         setOpenTost(true);

  //         setTostMessage('Message sent successfully!');
  //         setTostType('success');
  //         setLoader(false);

  //         setFormData({
  //           from_name: '',
  //           from_email: '',
  //           message: '',
  //           to_name: 'DataEcho Team',
  //         });
  //       },
  //       (error) => {
  //         // Handle error in sending email
  //         setLoader(false);
  //         setOpenTost(true);
  //         setTostMessage(error.text);
  //         setTostType('error');
  //         setFormData({
  //           from_name: '',
  //           from_email: '',
  //           message: '',
  //           to_name: 'DataEcho Team',
  //         });
  //       }
  //     );
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoader(true);

    try {
      const { status, msg } = validateConnectUs(formData);
      if (!status) {
        setLoader(false);
        setOpenTost(true);
        setTostMessage(msg);
        setTostType('error');
        return;
      }

      const formDataUpdated = { ...formData, captcha: captchaStr };

      const response = await axios.post('/user/contact-us', formDataUpdated);
      setFormData({
        from_name: '',
        from_email: '',
        message: '',
        to_name: 'DataEcho Team',
      });
      setLoader(false);

      if (response.data.status === 'OK') {
        setTostMessage(response.data.message);
        setTostType('success');
        setTimeout(() => {
          setOpenTost(false);
        }, 2000);
      } else if (response.data.status === 'error') {
        setTostMessage('Invalid Message, Please try again');
        setTostType('error');
      }

      setOpenTost(true);
    } catch (e) {
      setFormData({
        from_name: '',
        from_email: '',
        message: '',
        to_name: 'DataEcho Team',
      });
      setLoader(false);
      setTimeout(() => {
        setOpenTost(false);
      }, 2000);
      if (e.code === 'ERR_NETWORK') {
        setTostMessage(e.message);
      } else if (e.code === 'ERR_BAD_REQUEST') {
        setTostMessage('Invalid message');
      } else {
        setTostMessage(e.response.data.detail);
      }
      setTostType('error');
      setOpenTost(true);
    }
  };

  const handleCaptchChange = (value) => {
    setCaptchaStr(value);
  };

  if (type === 'dataEcho') {
    return (
      <div className="ContactUs-contact-container">
        <div className="ContactUs-inner-container">
          <div className="ContactUs-div-container">
            <div>
              <h1 className="ContactUs-title">Contact Us</h1>

              <form autoComplete="off" onSubmit={handleSubmit}>
                <Input
                  label={'Name'}
                  onChange={handleChange}
                  name="from_name"
                  value={formData.from_name}
                  placeholder={'Enter Full Name'}
                />
                <Input
                  onChange={handleChange}
                  name="to_name"
                  value={'DataEcho Team'}
                  display={'none'}
                />
                <Input
                  label={'Email'}
                  inputType={'email'}
                  onChange={handleChange}
                  name="from_email"
                  value={formData.from_email}
                  placeholder={'Enter your email'}
                />
                <Input
                  label={'Message'}
                  type="textarea"
                  onChange={handleChange}
                  name="message"
                  value={formData.message}
                />
                <div className="recaptcha-container">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    size="normal"
                    nonce={document
                      .querySelector('meta[name="csp-nonce"]')
                      .getAttribute('content')}
                    onChange={handleCaptchChange}
                  />
                </div>
                <div className="ContactUs-button-container">
                  <Button text={'Submit'} />
                </div>
              </form>
            </div>
          </div>

          <img src={CONTACT} className="ContactUs-image" alt="Contact" />
        </div>
        <Loader loader={loader} setLoader={setLoader} />
        <Tost
          message={tostMessage}
          openTost={openTost}
          setOpenTost={setOpenTost}
          type={tostType}
        />
      </div>
    );
  } else if (type === 'landing') {
    return (
      <div className="ContactUs-contact-container">
        <div className="ContactUs-inner-container-2">
          <div className="ContactUs-width-div">
            <h3 className="ContactUs-title">Contact Us</h3>

            <form
              autoComplete="off"
              className="ContactUs-form"
              onSubmit={handleSubmit}
            >
              <Input
                label={'Name'}
                onChange={handleChange}
                name="from_name"
                value={formData.from_name}
                placeholder={'Enter Full Name'}
              />
              <Input
                // label={"To"}
                onChange={handleChange}
                name="to_name"
                value={'DataEcho Team'}
                display={'none'}
              />
              <Input
                label={'Email'}
                onChange={handleChange}
                name="from_email"
                value={formData.from_email}
                placeholder={'Enter your email'}
              />
              <Input
                label={'Message'}
                type="textarea"
                onChange={handleChange}
                name="message"
                value={formData.message}
              />
              <div className="recaptcha-container">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  size="normal"
                  nonce={document
                    .querySelector('meta[name="csp-nonce"]')
                    .getAttribute('content')}
                  onChange={handleCaptchChange}
                />
              </div>
              <div className="ContactUs-button-container">
                <Button text={'Submit'} />
              </div>
            </form>
          </div>

          <img
            src={groupLogo}
            className="ContactUs-image1"
            alt="Group Logo"
            width="300px"
          />
        </div>
        <Loader loader={loader} setLoader={setLoader} />
        <Tost
          message={tostMessage}
          openTost={openTost}
          setOpenTost={setOpenTost}
          type={tostType}
        />
      </div>
    );
  }
}

export default ContactUs;
