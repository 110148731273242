import React from "react";
import ActivityCards from "./ActivityCards";
import research from "../images/reserach_new.png";
import event from "../images/event1_new.png";
import "./Styles/Activities.css"; // Import the CSS file

import { useNavigate } from "react-router-dom";

const Activities = React.forwardRef((props, ref) => {
  const navigate = useNavigate();

  return (
    <div className="Activities-activities-container" ref={ref}>
      <div className="Activities-cards-wrapper">
        <div
          className="Activities-clickable-div"
          onClick={() => navigate("/researchDetails")}
        >
          <ActivityCards
            image={research}
            heading={"Research"}
            content={
              "Delve in our pioneering studies and the latest advancements"
            }
          />
        </div>

        <div
          className="Activities-clickable-div"
          onClick={() => navigate("/eventsDetails")}
        >
          <ActivityCards
            image={event}
            heading={"Events"}
            content={
              "A peep into live discussions, workshops, and networking in the data realm."
            }
          />
        </div>
      </div>
    </div>
  );
});

export default Activities;
