import React, { useState, useRef, useEffect } from 'react';
import Modal from './Modal';
import MyCarousel from './MyCarousel';
import Button from './Button';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import 'react-toastify/dist/ReactToastify.css';
import Tost from './Tost/Tost';
import Loader from './Loader/Loader';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import GetEmailForm from './ForgetPassword/GetEmailForm';
import GetOTPForm from './ForgetPassword/GetOTPForm';
import ChangePasswordForm from './ForgetPassword/ChangePasswordForm';
import { validateLogin } from '../Utils/FormValidation';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import './Styles/LoginModal.css'; // Import the CSS file
import './LoginStyle.css';
import CryptoJS from 'crypto-js';
import Disableautofill from 'disableautofill';
import ReCAPTCHA from 'react-google-recaptcha';

function LoginModal({
  open,
  setOpen,
  openSignup,
  setOpenSignup,
  openTost,
  setOpenTost,
}) {
  const formRef = useRef(null);
  let mainInstance = null;

  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [loginForm, setLoginForm] = useState({
    email: '',
    password: '',
  });
  const [errorMsg, setErrorMsg] = useState('');

  const axios = useAxiosPrivate();
  const { auth, setAuth } = useAuth();

  const [tostMessage, setTostMessage] = useState('Oops Something went wrong');
  const [tostType, setTostType] = useState('error');

  const [forgetPassword, setForgetPassword] = useState('uncheck');

  const [resetToken, setResetToken] = useState('');

  const [captchaStr, setCaptchaStr] = useState('');

  useEffect(() => {
    if (formRef.current) {
      mainInstance = new Disableautofill(formRef.current, {
        fields: ['.LoginModal-input'],
        callback: () => true,
      });

      return () => {
        if (mainInstance) {
          mainInstance.destroy();
        }
      };
    }
  }, []);

  const handleOnChange = (event) => {
    const { name, value } = event.target;

    setLoginForm((prev) => ({ ...prev, [name]: value }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const encryptPassword = (password) => {
    const secretKey = CryptoJS.enc.Utf8.parse('1234567890123456'); // 16 bytes key
    const iv = CryptoJS.lib.WordArray.random(16); // Generate a random IV
    const encrypted = CryptoJS.AES.encrypt(password, secretKey, {
      iv: iv,
    }).toString();
    const encryptedIv = iv.toString(CryptoJS.enc.Hex); // Convert IV to hex for easy transfer
    return `${encrypted} ${encryptedIv}`;
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoader(true);

    try {
      const { status, msg } = validateLogin(loginForm);
      if (!status) {
        setLoader(false);
        setOpenTost(true);
        setTostMessage(msg);
        setTostType('error');
        return;
      }

      const encryptedPassword = encryptPassword(loginForm.password);
      // console.log(encryptedPassword);
      const formData = new FormData();

      formData.append('username', loginForm.email);
      formData.append('password', encryptedPassword);
      formData.append('recaptch_response', captchaStr);

      localStorage.setItem('email', loginForm.email);

      const response = await axios.post(`/auth/token`, formData);
      // console.log(response);
      setLoader(false);

      setAuth((prev) => ({
        ...prev,
        token: response.data.access_token,
        email: response.data.email,
        role: response.data.role,
        fullName: response.data.full_name,
      }));

      if (response.data.status === 'OK') {
        setTostMessage('Loggedin Successfully!!');
        setTostType('success');
        setTimeout(() => {
          setOpen(false);
          setOpenTost(false);
        }, 2000);
      } else if (response.data.status === 'error') {
        setTostMessage('Invalid Credentials');
        setTostType('error');
      }

      setOpenTost(true);

      navigate('/dataecho');
    } catch (e) {
      // console.log(e);
      setLoader(false);
      setTimeout(() => {
        setOpenTost(false);
      }, 2000);
      if (e.code === 'ERR_NETWORK') {
        setTostMessage(e.message);
      } else if (e.code === 'ERR_BAD_REQUEST') {
        setTostMessage('Invalid Credentials');
      } else {
        setTostMessage(e.response.data.detail);
      }
      setTostType('error');
      setOpenTost(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCaptchChange = (value) => {
    setCaptchaStr(value);
  };

  if (forgetPassword === 'uncheck') {
    return (
      <Modal isOpen={open} handleClose={handleClose}>
        <form ref={formRef} autoComplete="new-password" onSubmit={handleLogin}>
          <input type="hidden" value="somevalue" />

          <div className="LoginModal-container">
            <div className="LoginModal-leftSide">
              <MyCarousel />
            </div>
            <div className="LoginModal-rightSide">
              <h1 className="LoginModal-title">Login</h1>

              <span className="LoginModal-label">Email</span>
              <input
                autoComplete="one-time-code"
                type="email"
                aria-autocomplete="none"
                className="LoginModal-input"
                value={loginForm.email}
                name="email"
                onChange={handleOnChange}
                required
              />

              <span className="LoginModal-label">Password</span>
              <div className="LoginModal-passwordContainer">
                <input
                  autoComplete="new-password"
                  id="password"
                  aria-autocomplete="none"
                  className="LoginModal-passwordInput"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={loginForm.password}
                  required
                  onChange={handleOnChange}
                  onFocus={(e) =>
                    e.target.setAttribute('autoComplete', 'new-password')
                  }
                />
                {showPassword ? (
                  <FaEyeSlash
                    className="LoginModal-eyeOpen"
                    color="gray"
                    onClick={togglePasswordVisibility}
                  />
                ) : (
                  <FaEye
                    className="LoginModal-eyeClose"
                    color="gray"
                    onClick={togglePasswordVisibility}
                  />
                )}
              </div>

              <div className="recaptcha-container">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  size="normal"
                  nonce={document
                    .querySelector('meta[name="csp-nonce"]')
                    .getAttribute('content')}
                  onChange={handleCaptchChange}
                />
              </div>

              <div className="LoginModal-forgetPasswordWrapper">
                <span
                  className="LoginModal-forgotPassword"
                  onClick={() => setForgetPassword('checked')}
                >
                  Forgot Password ?
                </span>
              </div>

              <Button text={'Login'} />
              <span
                className="LoginModal-signUp"
                onClick={() => {
                  setOpen(false);
                  setOpenSignup(true);
                }}
              >
                Don't have an Account ? SignUp
              </span>
            </div>
          </div>
          {/* Toast and Loader components */}
          <Loader loader={loader} setLoader={setLoader} />
          <Tost
            message={tostMessage}
            type={tostType}
            openTost={openTost}
            setOpenTost={setOpenTost}
          />
        </form>
      </Modal>
    );
  } else if (forgetPassword === 'checked') {
    return (
      <Modal isOpen={open} handleClose={handleClose}>
        <GetEmailForm
          openTost={openTost}
          setOpenTost={setOpenTost}
          setForgetPassword={setForgetPassword}
        />
      </Modal>
    );
  } else if (forgetPassword === 'otp') {
    return (
      <Modal isOpen={open} handleClose={handleClose}>
        {/* GetOTPForm component */}
        <GetOTPForm
          openTost={openTost}
          setOpenTost={setOpenTost}
          setForgetPassword={setForgetPassword}
          setResetToken={setResetToken}
        />
      </Modal>
    );
  } else if (forgetPassword === 'change') {
    return (
      <Modal isOpen={open} handleClose={handleClose}>
        {/* ChangePasswordForm component */}
        <ChangePasswordForm
          openTost={openTost}
          setOpenTost={setOpenTost}
          setForgetPassword={setForgetPassword}
          resetToken={resetToken}
        />
      </Modal>
    );
  }
}

export default LoginModal;
