import React from "react";
import "./Styles/Heading.css"; // Import the CSS file

function Heading({ text, classN }) {
  return (
    <div className={`Heading-div ${classN}`}>
      <div className="Heading-box" />
      <span className="Heading-text">{text}</span>
    </div>
  );
}

export default Heading;
