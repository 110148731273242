import React, { useEffect, useState, useContext } from "react";
import Button from "../components/Button";
import Heading from "../components/Heading";
import D5_Stress_Paradigm from "../images/paradigm_updated.jpeg";
import SampleDataLineChart from "../components/Charts/SampleDataLineChart";
import ChannelList from "../components/Details/ChannelList";
import Modal from "../components/Modal";
import FeedbackModal from "../components/FeedbackModal";
import { ScrollContext } from "../context/ScrollContext";
import NavBar from "../components/NavBar";
import LogoBar from "../components/LogoBar";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { BsCircleFill } from "react-icons/bs";
import "./DataDetails.css";

const dataContent = {
  eeg: `A 'Brain Product' actiCAP slim/snap was employed for data collection, utilizing gel-based active electrodes to ensure top-notch recordings. The data was captured from 32 channels, with 28 channels specifically allocated for EEG ['Fp1', 'Fz', 'F3', 'F7', 'FT9', 'FC5', 'FC1', 'C3', 'CP5', 'CP1', 'Pz', 'P3', 'P7', 'O1', 'Oz', 'O2', 'P4', 'P8', 'CP6', 'CP2', 'Cz', 'C4', 'FT10', 'FC6', 'FC2', 'F4', 'F8', 'Fp2'].  The channel placements are as per the 10-20 placement system. Brain Vision served as the tool for recording and analysis of the data, with EEG data recorded at a sampling frequency of 250 Hz. The data format was stored across three types of files: a brain vision header file (.vhdr), the EEG data file (.eeg), and a marker file (.vmrk).`,
  eog: `A 'Brain Product' actiCAP slim/snap was employed for data collection, utilizing gel-based active electrodes to ensure top-notch recordings. The data was captured from 32 channels, with 2 channels designated for EOG [‘EOGR’,’ EOGL’]. Vertical and horizontal EOG signals were calculated using these electrodes. Brain Vision served as the tool for recording and analysis of the data, with EOG data recorded at a sampling frequency of 250 Hz. The data format was saved in three distinct file types: a file with a brain vision header (.vhdr), a file containing EEG data (.eeg), and a marker file (.vmrk).`,
  ecg: `'Brain Product' actiCAP slim/snap was employed for data collection, utilizing gel-based active electrodes to ensure top-notch recordings. The data was captured from 32 channels, with 2 channels designated for ECG. The data were acquired using a 2-channel ECG [‘ECGR’, ‘ECGL’] electrode from Brain Products. ECG data was recorded at a sampling frequency of 250 Hz. The data format was stored across three types of files: a brain vision header file (.vhdr), the EEG data file (.eeg), and a marker file (.vmrk).`,
  gsr: `The data were acquired using a Shimmer3 GSR device at 60Hz sampling frequency. The data format was stored across two types of files: A file from Tobii software (.plof and .xls).`,
  eye_gaze: `Eye gaze data collected via Tobii Nano Pro provides vital insights into an individual's mental and emotional states. Factors like pupil size and changes in eye position play crucial roles in analyzing this data. By tracking eye movements, specialists can reveal extensive information about an individual, including their focus points during tasks, offering insights into cognitive processes such as reading, problem-solving, and decision-making. Moreover, deviations in eye movement patterns may signal deceptive behaviors. The data is captured at a sampling frequency of 60 Hz, enabling thorough monitoring and analysis.`,
};

function DataDetails({ navigate, params }) {
  const [eegData, setEEGData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selected, setSelected] = useState(0);
  const { skipref } = useContext(ScrollContext);

  const axios = useAxiosPrivate();

  useEffect(() => {
    fetchData1();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // Function to handle checkbox change
  const handleCheckboxChange = (option) => {
    // Check if the option is already selected
    const selectedIndex = selectedOptions.indexOf(option);

    if (selectedIndex === -1) {
      // If not selected, add it to the array
      setSelectedOptions([...selectedOptions, option]);
    } else {
      // If selected, remove it from the array
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    }
  };

  const fetchData1 = async () => {
    try {
      const response = await axios.get(
        `/access/sample/${params !== null ? params?.type : "eeg"}`
      );
      const data = response.data.data;
      setEEGData(data);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <NavBar navigate={navigate} type={"dataEcho"} />
      <LogoBar navigate={navigate} type={"dataEcho"} />
      <div className="DataDetails-container" ref={skipref}>
        <div className="DataDetails-content-wrapper">
          <Heading
            text={`Stress ${
              params !== null ? params.type.toUpperCase() : "eeg"
            }`}
          />
          <p className="DataDetails-description">
            {dataContent[params !== null ? params.type.toLowerCase() : "eeg"]}
          </p>
          <Heading text={"Select a channel to visualize the data "} />
          <ChannelList
            type={params !== null ? params.type : "eeg"}
            selected={selected}
            setSelected={setSelected}
          />
          <div className="DataDetails-chart-container">
            <div className="DataDetails-chart-legend">
              <div className="DataDetails-legend-item">
                <BsCircleFill color="var(--primary-color)" />
                <span className="DataDetails-legend-text">
                  x-axis:{" "}
                  <span className="DataDetails-legend-text-inner">
                    Timestamp
                  </span>
                </span>
              </div>
              <div className="DataDetails-legend-item">
                <BsCircleFill color="var(--primary-color)" />
                <span className="DataDetails-legend-text">
                  y-axis:{" "}
                  <span className="DataDetails-legend-text-inner">
                    Values (in µV)
                  </span>
                </span>
              </div>
            </div>
          </div>
          <SampleDataLineChart eeg={eegData} index={selected} />

          <div>
            <Button text={"Show Interest"} onClick={handleOpen} />
          </div>
        </div>
        <FeedbackModal
          open={open}
          handleClose={handleClose}
          handleCheckboxChange={handleCheckboxChange}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
        />
      </div>
    </>
  );
}

export default DataDetails;
