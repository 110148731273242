const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/;
const mobileNoRegex = /^\d{10}$/;
const fullNameR = /^[A-Za-z\s]+$/;
const institutionR = /^[A-Za-z\s]+$/;
const departmentR = /^[a-zA-Z0-9_@#& -]*$/;
const designationR = /^[a-zA-Z0-9_@#& -]*$/;
const purposeR = /^[a-zA-Z0-9_,&. ]*$/;
const descR = /^[a-zA-Z0-9_@#.&, ]*$/;

export const validateSignup = (
  {
    full_name,
    email,
    mobile_no,
    institution,
    institution_email,
    department,
    designation,
    password,
  },
  setError
) => {
  if (!fullNameR.test(full_name)) {
    setError((prev) => ({ ...prev, full_name: 'Please enter valid name' }));
    return false;
  }
  if (!emailRegex.test(email)) {
    setError((prev) => ({ ...prev, email: 'please enter valid email' }));
    return false;
  }
  if (!emailRegex.test(institution_email)) {
    setError((prev) => ({
      ...prev,
      institution_email: 'please enter valid email',
    }));
    return false;
  }
  if (!passwordRegex.test(password)) {
    setError((prev) => ({ ...prev, password: 'please enter valid password' }));
    return false;
  }

  if (!mobileNoRegex.test(mobile_no)) {
    setError((prev) => ({
      ...prev,
      mobile_no: 'please enter valid mobile number',
    }));
    return false;
  }

  if (!institutionR.test(institution)) {
    setError((prev) => ({
      ...prev,
      institution: 'Please enter valid institutions',
    }));
    return false;
  }

  if (!departmentR.test(department)) {
    setError((prev) => ({
      ...prev,
      department: 'Please enter valid department',
    }));
    return false;
  }

  if (!designationR.test(designation)) {
    setError((prev) => ({
      ...prev,
      designation: 'Please enter valid designation',
    }));
    return false;
  }

  setError({
    full_name: '',
    email: '',
    mobile_no: '',
    institution: '',
    institution_email: '',
    department: '',
    designation: '',
    password: '',
  });

  return true;
};

export const validateLogin = ({ email, password }) => {
  const [local, domain] = email.split('@');

  if (!emailRegex.test(email)) {
    return { status: false, msg: 'Invalid credentials' };
  } else if (local.length > 64) {
    return { status: false, msg: 'Invalid credentials' };
  } else if (domain.length > 190) {
    return { status: false, msg: 'Invalid credentials' };
  }

  if (!passwordRegex.test(password)) {
    return { status: false, msg: 'Invalid credentials' };
  } else if (password.length < 8 || password.length > 16) {
    return { status: false, msg: 'Invalid credentials' };
  }

  return { status: true };
};

export const validateEmail = (email) => {
  const [local, domain] = email.split('@');

  if (!emailRegex.test(email)) {
    return { status: false, msg: 'Please enter valid email' };
  } else if (local.length > 64) {
    return { status: false, msg: 'Email too long' };
  } else if (domain.length > 190) {
    return { status: false, msg: 'Email too long' };
  }

  return { status: true };
};

export const validateOTP = (otp) => {
  if (otp.length !== 6) {
    return { status: false, msg: 'Please enter valid OTP' };
  }

  return { status: true };
};

export const validatePasswords = (password, rePassword) => {
  if (password !== rePassword) {
    return { status: false, msg: 'Both the passwords must be same' };
  } else if (!passwordRegex.test(password)) {
    return { status: false, msg: 'Please enter valid password' };
  } else if (password.length < 8 || password.length > 16) {
    return { status: false, msg: 'Password must be 8 to 16 characters' };
  }

  return { status: true };
};

export const validateFeedback = (purpose, subCount) => {
  if (!purposeR.test(purpose)) {
    return { status: false, msg: 'Invalid text' };
  }

  const words = purpose.split(' ').filter((word) => word.length > 0);
  if (words.length >= 100) {
    return { status: false, msg: 'Please write within 100 words' };
  }

  if (subCount <= 0) {
    return { status: false, msg: 'Subject count must be greater than 0' };
  }

  return { status: true };
};

export const validatePublish = (description) => {
  if (!descR.test(description)) {
    return { status: false, msg: 'Invalid text' };
  }

  const words = description.split(' ').filter((word) => word.length > 0);
  if (words.length >= 100) {
    return { status: false, msg: 'Please write within 100 words' };
  }

  return { status: true };
};

export const validateCustomizeOrder = (description, subCount) => {
  if (!descR.test(description)) {
    return { status: false, msg: 'Invalid text' };
  }

  const words = description.split(' ').filter((word) => word.length > 0);
  if (words.length >= 100) {
    return { status: false, msg: 'Please write within 100 words' };
  }

  if (subCount <= 0) {
    return { status: false, msg: 'Subject count must be greater than 0' };
  }

  return { status: true };
};

export const validateConnectUs = ({
  from_name,
  from_email,
  message,
  to_name,
}) => {
  const words = message.split(' ').filter((word) => word.length > 0);
  if (words.length >= 100) {
    return { status: false, msg: 'Please write within 100 words' };
  }

  // console.log(to_name);
  if (!fullNameR.test(from_name)) {
    return { status: false, msg: 'Invalid full name' };
  }
  if (!emailRegex.test(from_email)) {
    return { status: false, msg: 'Invalid email' };
  }
  if (!descR.test(message)) {
    return { status: false, msg: 'Invalid text' };
  }
  if (!fullNameR.test(to_name)) {
    return { status: false, msg: 'Invalid name' };
  }

  return { status: true };
};
