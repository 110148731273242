import React from "react";
import Card from "./Card";
import ima1 from "../images/cylinder_new.png";
import ima2 from "../images/img2_new.png";
import ima3 from "../images/img3_new.png";
import ima4 from "../images/img4_new.png";

import { useNavigate } from "react-router-dom";
import "./Styles/Services.css"; // Import the CSS file

const Services = React.forwardRef((props, ref) => {
  const navigate = useNavigate();

  return (
    <div className="Services-container" ref={ref}>
      <div className="Card-wrapper">
        <div
          className="Clickable-card"
          onClick={() => navigate("/exploreData")}
        >
          <Card
            image={ima1}
            heading={"Explore It"}
            content={"Discover the diverse dataecho"}
          />
        </div>

        <div
          className="Clickable-card"
          onClick={() => navigate("/customizeOrder")}
        >
          <Card
            image={ima2}
            heading={"Custom Fit"}
            content={"Get tailored data solutions"}
          />
        </div>

        <div
          className="Clickable-card"
          onClick={() => navigate("/publishDataset")}
        >
          <Card
            image={ima3}
            heading={"Enrich It"}
            content={"Be part of empowering communities"}
          />
        </div>

        <div
          className="Clickable-card"
          onClick={() => navigate("/DataConsultancy")}
        >
          <Card
            image={ima4}
            heading={"Data Consultancy"}
            content={"Need our help?"}
          />
        </div>
      </div>
    </div>
  );
});

export default Services;
