import React, { useState } from 'react';
import Modal from './Modal';
import useAuth from '../hooks/useAuth';
import Button from './Button';
import { validateSignup } from '../Utils/FormValidation';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import Loader from './Loader/Loader';
import Tost from './Tost/Tost';
import './Styles/SignupModal.css'; // Import the CSS file

function SignupModal({
  open,
  setOpen,
  openSignup,
  setOpenSignup,
  setOpenTost,
  openTost,
}) {
  const { auth, setAuth } = useAuth();
  const [registration, setRegistration] = useState({
    full_name: '',
    email: '',
    mobile_no: '',
    institution: '',
    institution_email: '',
    department: '',
    designation: '',
    password: '',
  });

  const [error, setError] = useState({
    full_name: '',
    email: '',
    mobile_no: '',
    institution: '',
    institution_email: '',
    department: '',
    designation: '',
    password: '',
  });

  const [loader, setLoader] = useState(false);
  const [tostMessage, setTostMessage] = useState('Oops something went wrong!!');
  const [tostType, setTostType] = useState('error');

  const axios = useAxiosPrivate();

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setRegistration((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validation = validateSignup(registration, setError);
    if (!validation) {
      return;
    }
    setLoader(true);
    try {
      const response = await axios.post(`/auth/register`, registration);

      setOpenTost(true);
      setTostMessage(response.data.Status);
      setTostType('success');
      setTimeout(() => {
        setOpenTost(false);
        setOpenSignup(false);
      }, 3000);

      setRegistration({
        full_name: '',
        email: '',
        mobile_no: '',
        institution: '',
        institution_email: '',
        department: '',
        designation: '',
        password: '',
      });
      setLoader(false);
    } catch (e) {
      setLoader(false);
      setTostMessage(e.message);
      setTimeout(() => {
        setOpenTost(false);
      }, 3000);
      setOpenTost(true);
      setTostType('error');
      setRegistration({
        full_name: '',
        email: '',
        mobile_no: '',
        institution: '',
        institution_email: '',
        department: '',
        designation: '',
        password: '',
      });
    }
  };

  return (
    <Modal isOpen={openSignup} handleClose={() => setOpenSignup(false)}>
      <form
        autoComplete="off"
        aria-autocomplete="none"
        className="SignupModal-Form"
        onSubmit={handleSubmit}
      >
        <div className="SignupModal-FormContainer">
          <h1 className="SignupModal-Title">SignUp</h1>
          <span className="SignupModal-Label">Full Name</span>
          <input
            autoComplete="off"
            aria-autocomplete="none"
            className="SignupModal-Input"
            value={registration.full_name}
            onChange={handleOnChange}
            name="full_name"
            required
          />
          <span className="SignupModal-ErrorMessage">{error.full_name}</span>

          <span className="SignupModal-Label">Email</span>
          <input
            autoComplete="off"
            aria-autocomplete="none"
            className="SignupModal-Input"
            type="email"
            value={registration.email}
            onChange={handleOnChange}
            name="email"
            required
          />
          <span className="SignupModal-ErrorMessage">{error.email}</span>

          <span className="SignupModal-Label">Mobile No.</span>
          <input
            autoComplete="off"
            aria-autocomplete="none"
            className="SignupModal-Input"
            type="text"
            value={registration.mobile_no}
            onChange={handleOnChange}
            name="mobile_no"
            required
          />
          <span className="SignupModal-ErrorMessage">{error.mobile_no}</span>

          <span className="SignupModal-Label">Institution</span>
          <input
            autoComplete="off"
            aria-autocomplete="none"
            className="SignupModal-Input"
            type="text"
            value={registration.institution}
            onChange={handleOnChange}
            name="institution"
            required
          />
          <span className="SignupModal-ErrorMessage">{error.institution}</span>

          <span className="SignupModal-Label">Institution Email</span>
          <input
            autoComplete="off"
            aria-autocomplete="none"
            className="SignupModal-Input"
            type="email"
            value={registration.institution_email}
            onChange={handleOnChange}
            name="institution_email"
            required
          />
          <span className="SignupModal-ErrorMessage">
            {error.institution_email}
          </span>

          <span className="SignupModal-Label">Department</span>
          <input
            autoComplete="off"
            aria-autocomplete="none"
            className="SignupModal-Input"
            type="text"
            value={registration.department}
            onChange={handleOnChange}
            name="department"
            required
          />
          <span className="SignupModal-ErrorMessage">{error.department}</span>

          <span className="SignupModal-Label">Designation</span>
          <input
            autoComplete="off"
            aria-autocomplete="none"
            className="SignupModal-Input"
            type="text"
            value={registration.designation}
            onChange={handleOnChange}
            name="designation"
            required
          />
          <span className="SignupModal-ErrorMessage">{error.designation}</span>

          <span className="SignupModal-Label">Password</span>
          <input
            autoComplete="off"
            aria-autocomplete="none"
            className="SignupModal-Input"
            type="password"
            value={registration.password}
            onChange={handleOnChange}
            name="password"
            required
          />
          <span className="SignupModal-ErrorMessage">{error.password}</span>

          <Button text={'Signup'} />

          <span
            className="SignupModal-SwitchToLogin"
            onClick={() => {
              setOpen(true);
              setOpenSignup(false);
            }}
          >
            Already have an Account ?
          </span>
        </div>
      </form>
      <Loader loader={loader} setLoader={setLoader} />
      <Tost
        message={tostMessage}
        openTost={openTost}
        setOpenTost={setOpenTost}
        type={tostType}
      />
    </Modal>
  );
}

export default SignupModal;
