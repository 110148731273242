import React from "react";
import "./Styles/ActivityCards.css"; // Import the CSS file

function ActivityCards({ image, heading, content }) {
  return (
    <div className="ActivityCards-styled-div">
      <div className="my-hover">
        <img className="ActivityCards-image" src={image} alt={heading} />
        <div className="ActivityCards-content-div">
          <h4 className="ActivityCards-heading">{heading}</h4>
          <h6 className="ActivityCards-subheading">{content}</h6>
        </div>
      </div>
    </div>
  );
}

export default ActivityCards;
