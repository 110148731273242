import React, { useState } from 'react';
import Button from '../Button';
import Tost from '../Tost/Tost';
import Loader from '../Loader/Loader';
import MyCarousel from '../MyCarousel';
import OtpInput from 'react-otp-input';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate } from 'react-router-dom';
import './ForgetPassword.css'; // Import the CSS file
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { validatePasswords } from '../../Utils/FormValidation';
import ReCAPTCHA from 'react-google-recaptcha';

function ChangePasswordForm({
  setOpen,
  setOpenSignup,
  openTost,
  setOpenTost,
  setForgetPassword,
  resetToken,
}) {
  const [password, setPassword] = useState('');
  const [cPassword, setCPassword] = useState('');
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const [tostMessage, setTostMessage] = useState('Oops something went wrong');
  const [tostType, setTostType] = useState('error');
  const [captchaValue, setCaptchaValue] = useState('');

  const axios = useAxiosPrivate();
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleResetPassword = async (event) => {
    event.preventDefault();

    try {
      setLoader(true);

      const { status, msg } = validatePasswords(password, cPassword);
      if (!status) {
        setLoader(false);
        setOpenTost(true);
        setTostMessage(msg);
        setTostType('error');

        return;
      }

      const requestObj = {
        email: localStorage.getItem('email'),
        password: password,
        reset_token: resetToken,
        captcha: captchaValue,
      };

      const response = await axios.post('/auth/reset_password', requestObj);
      // console.log(response.data);

      setTostMessage(response.data.status);
      setTostType('success');

      setTimeout(() => {
        setOpenTost(false);
        setForgetPassword('uncheck');
      }, 2000);

      setOpenTost(true);
      setLoader(false);
    } catch (e) {
      setLoader(false);
      // console.log(e);
      if (e?.response?.status === 429) {
        setTostMessage(e.response.data.detail);
      } else {
        setTostMessage(e.message);
      }
      setTostType('error');
      setTimeout(() => {
        setOpenTost(false);
      }, 2000);

      setOpenTost(true);
    }
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  return (
    <form
      autoComplete="off"
      className="ChangePasswordForm-FormContainer"
      onSubmit={handleResetPassword}
    >
      <div className="ChangePasswordForm-CarouselWrapper">
        <MyCarousel />
      </div>
      <div className="ChangePasswordForm-FormContent">
        <h1 className="ChangePasswordForm-FormTitle">Change Password</h1>

        <span className="ChangePasswordForm-Label">Password</span>
        <div className="LoginModal-passwordContainer">
          <input
            autoComplete="off"
            aria-autocomplete="none"
            type={showPassword ? 'text' : 'password'}
            className="LoginModal-passwordInput"
            value={password}
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {showPassword ? (
            <FaEyeSlash
              className="LoginModal-eyeOpen"
              color="gray"
              onClick={togglePasswordVisibility}
            />
          ) : (
            <FaEye
              className="LoginModal-eyeClose"
              color="gray"
              onClick={togglePasswordVisibility}
            />
          )}
        </div>

        <span className="ChangePasswordForm-Label">Confirm Password</span>
        <div className="LoginModal-passwordContainer">
          <input
            autoComplete="off"
            aria-autocomplete="none"
            type={showPassword1 ? 'text' : 'password'}
            className="LoginModal-passwordInput"
            value={cPassword}
            name="password"
            onChange={(e) => setCPassword(e.target.value)}
            required
          />
          {showPassword1 ? (
            <FaEyeSlash
              className="LoginModal-eyeOpen"
              color="gray"
              onClick={() => setShowPassword1(!showPassword1)}
            />
          ) : (
            <FaEye
              className="LoginModal-eyeClose"
              color="gray"
              onClick={() => setShowPassword1(!showPassword1)}
            />
          )}
        </div>

        <div className="re-captcha-wrapper">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            size="normal"
            onChange={handleCaptchaChange}
          />
        </div>

        <span
          className="ChangePasswordForm-BackLink"
          onClick={() => setForgetPassword('otp')}
        >
          Back
        </span>

        <Button text={'Submit'} />
      </div>

      <Tost
        message={tostMessage}
        openTost={openTost}
        setOpenTost={setOpenTost}
        type={tostType}
      />
      <Loader loader={loader} setLoader={setLoader} />
    </form>
  );
}

export default ChangePasswordForm;
