import React, { useState } from "react";
import "./NewsComponent.css"; // Import the CSS file

function NewsCard({ heading, headline, image, siteLink, date }) {
  const [hover, setHover] = useState(false);

  return (
    <div
      className="NewsCard-CardWrapper"
      onClick={() => window.open(siteLink, "_blank")}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="NewsCard-CardContent">
        <div>
          <p className="NewsCard-DateText">{date}</p>
          <h3 className="NewsCard-HeadingText">{heading}</h3>
          <div className="NewsCard-HeadlineText">{headline}</div>
        </div>
        <div className="NewsCard-ImageWrapper">
          <img className="NewsCard-Image" src={image} alt="News Image" />
        </div>
      </div>
    </div>
  );
}

export default NewsCard;
