import React, { useEffect } from "react";
import Chart from "react-apexcharts";

function SampleDataLineChart({ eeg, index }) {
  //   useEffect(() => {
  //     console.log(Fz);
  //   }, []);
  const chartOptions = {
    chart: {
      id: "main-chart",
      toolbar: {
        show: false, // Hide toolbar on the main chart
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        minWidth: 40, // Adjust the width of the y-axis labels as needed
      },
    },
    // title: {
    //   display: false,
    //   text: "Visualization",
    //   align: "center",
    //   style: {
    //     fontSize: "16px",
    //     fontWeight: "bold",
    //   },
    // },
    stroke: {
      width: 2, // Set the width of the main chart lines
      // colors: ["#000000"],
    },
    grid: {
      borderColor: "#f1f1f1", // Adjust grid line color as needed
    },
    markers: {
      size: 0, // Set marker size to 0 to hide markers on the main chart
    },
  };

  const brushOptions = {
    chart: {
      id: "brush-chart",
      brush: {
        target: "main-chart", // Specify the target chart for brushing
        enabled: true, // Enable brushing on the brush chart
      },
      selection: {
        enabled: true, // Enable the selection on the brush chart
        xaxis: {
          min: 0, // Set initial selection start date
          max: 1000, // Set initial selection end date
        },
      },
    },
    stroke: {
      colors: ["#b86b12"],
      width: 2,
    },
    xaxis: {
      labels: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false, // Hide y-axis labels on the brush chart
      },
    },
    grid: {
      borderColor: "#f1f1f1", // Adjust grid line color as needed
    },
    markers: {
      size: 0, // Set marker size to 0 to hide markers on the brush chart
    },
  };

  const series = [
    {
      type: "line",
      data: eeg[index],
    },
  ];

  return (
    <>
      <Chart
        type="line"
        options={chartOptions}
        series={series}
        height={500}
        width="100%"
      />

      <Chart
        type="area"
        options={brushOptions}
        series={series}
        height={150}
        width="100%"
      />
    </>
  );
}

export default SampleDataLineChart;
