import React from 'react';
import './Styles/LogoBar.css'; // Import the CSS file
import Cdac from '../images/text_logo.png';
import meity from '../images/meity_black.png';
import groupLogo from '../images/group-logo2.png';
import Logo from '../images/Logo.png';

function LogoBar({ navigate, type }) {
  if (type === 'dataEcho') {
    return (
      <div className="LogoBar-topDiv">
        <div className="LogoBar-div">
          <div className="LogoBar-container">
            <div
              className="LogoBar-section"
              onClick={() => navigate('/dataecho')}
            >
              <img src={meity} alt="MeitY" className="LogoBar-image1" />
              <img src={Cdac} alt="CDAC" className="LogoBar-image2" />
              <img
                src={groupLogo}
                alt="Group Logo"
                className="LogoBar-image3"
              />
            </div>
            <div className="LogoBar-titleSection">
              <h5 className="LogoBar-title">Neuro-Cognitive AI - Data Echo</h5>
              <img
                src={Logo}
                alt="Logo"
                className="LogoBar-image4"
                data-content="true"
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else if (type === 'landing') {
    return (
      <div className="LogoBar-topDiv">
        <div className="LogoBar-div">
          <div className="LogoBar-container">
            <div className="LogoBar-section" onClick={() => navigate('/')}>
              <img src={Cdac} alt="CDAC" className="LogoBar-image" width={55} />
              <img src={meity} alt="MeitY" className="LogoBar-image1" />
            </div>
            <div className="LogoBar-titleSection">
              <h5 className="LogoBar-title">Neuro-Cognitive AI</h5>
              <img
                src={groupLogo}
                alt="Group Logo"
                className="LogoBar-image3"
                data-contain="false"
                style={{ marginLeft: '12px' }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LogoBar;
