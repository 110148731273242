import React from "react";
import "./Styles/Button.css"; // Import the CSS file

function Button({ text, onClick, type, margin }) {
  if (type === "nav") {
    return (
      <button className="Button-nav-bar-button" onClick={onClick}>
        <span className="Button-text-style">{text}</span>
      </button>
    );
  } else {
    return (
      <button className="Button-styled-button" onClick={onClick}>
        <span className="Button-text-style">{text}</span>
      </button>
    );
  }
}

export default Button;
