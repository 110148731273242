import React from "react";
import NavBar from "../components/NavBar";
import LogoBar from "../components/LogoBar";
import Heading from "../components/Heading";
import "./Bmi.css";

function Bmi({ navigate }) {
  return (
    <>
      <NavBar navigate={navigate} type={"landing"} />
      <LogoBar navigate={navigate} type={"landing"} />
      <div className="Bmi-styled-container">
        <div className="Bmi-styled-inner-container">
          <Heading
            text={
              "A Brain Machine Interface enabled Assistive System for children with special needs"
            }
          />
          <div className="Bmi-justified-text">
            The objective of the proposed work is to design, develop and deploy
            a Computational framework as a Brain Machine Interface enabled
            Assistive communication system for persons with special needs. It
            covers the aspects of thematic areas right from core research to
            multidisciplinary neuro-cognitive computing for medical science for
            betterment of life, as mentioned below:
          </div>
          <ul>
            <li>
              The BMI command interface would be available in two forms –
              Standalone and Web-based interface.
            </li>
            <li>
              It would support an audio integrated standalone system to cater
              the basic requirement of dependent persons like – requirement of
              food, change in posture and daily routine needs.
            </li>
            <li>
              It would be enriched with a p300 odd-ball paradigm based BCI web
              browser with a speller of English and Devanagari Script support.
            </li>
            <li>
              A BMI interface-based Alert / warning generation system would be
              developed to cater the emergency cases of such types of patients.
            </li>
            <li>
              An assessment & validation procedure would be carried out in the
              form of training & workshop with the support of design paradigm
              prepared by domain experts considering targeted sample candidates
              on specific criteria.
            </li>
          </ul>
          <Heading text={"Use Cases"} />
          <ul>
            <li>
              Basic requirement of dependent children by mental imagery task
            </li>
            <li>P300 based speller for locked-in persons</li>
            <li>Mobile based communication for SoS alert</li>
          </ul>
          <Heading text={"Salient Features"} />
          <ul>
            <li>Indian language support</li>
            <li>Web and mobile compatibility</li>
            <li>Audio integrated standalone system</li>
            <li>Assessment & validation by renowned domain experts</li>
          </ul>

          <h4>Chief Investigator Details</h4>
          <h4>
            Naveen Kumar Jain, Sr. Director & Centre Head, C-DAC Delhi ,
            nkjain@cdac.in
          </h4>
        </div>
      </div>
    </>
  );
}

export default Bmi;
