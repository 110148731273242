import React, { useState } from 'react';
import Button from '../Button';
import Tost from '../Tost/Tost';
import Loader from '../Loader/Loader';
import MyCarousel from '../MyCarousel';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate } from 'react-router-dom';
import { validateEmail } from '../../Utils/FormValidation';
import './ForgetPassword.css'; // Import the CSS file
import ReCAPTCHA from 'react-google-recaptcha';

function GetEmailForm({
  setOpen,
  setOpenSignup,
  openTost,
  setOpenTost,
  setForgetPassword,
}) {
  const [email, setEmail] = useState('');
  const [loader, setLoader] = useState(false);
  const [tostMessage, setTostMessage] = useState('Oops something went wrong');
  const [tostType, setTostType] = useState('error');
  const [captchaValue, setCaptchaValue] = useState('');

  const axios = useAxiosPrivate();
  const navigate = useNavigate();

  const handleEmailSubmit = async (event) => {
    event.preventDefault();

    setLoader(true);
    try {
      const { status, msg } = validateEmail(email);

      if (!status) {
        setLoader(false);
        setOpenTost(true);
        setTostMessage(msg);
        setTostType('error');
        return;
      }

      const response = await axios.post('/auth/forget_password', {
        email: email,
        captcha: captchaValue,
      });
      // console.log(response.data);

      if (response.data.hasOwnProperty('error_reference')) {
        throw new Error('Invalid credentials');
      }

      setTostMessage('OTP Sent successfully');
      setTostType('success');
      setTimeout(() => {
        setOpenTost(false);
        setForgetPassword('otp');
      }, 2000);

      setOpenTost(true);
      setLoader(false);
    } catch (e) {
      setLoader(false);
      setTostType('error');
      // console.log(e.response);
      if (e?.response?.status === 429) {
        setTostMessage(e.response.data.detail);
      } else {
        setTostMessage(e.message);
      }

      setTimeout(() => {
        setOpenTost(false);
      }, 2000);

      setOpenTost(true);
    }
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  return (
    <form
      autoComplete="off"
      className="GetEmailForm-FormWrapper"
      onSubmit={handleEmailSubmit}
    >
      <div className="GetEmailForm-CarouselWrapper">
        <MyCarousel />
      </div>

      <div className="GetEmailForm-FormContainer">
        <h1 className="GetEmailForm-Title">Forget Password</h1>

        <span className="GetEmailForm-Label">Email</span>
        <input
          autoComplete="off"
          aria-autocomplete="none"
          className="GetEmailForm-Input"
          value={email}
          name="email"
          onChange={(e) => setEmail(e.target.value)}
          required
        />

        <div className="re-captcha-wrapper">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            size="normal"
            onChange={handleCaptchaChange}
          />
        </div>

        <div className="GetEmailForm-FlexContainer"></div>

        <Button text={'Submit'} />
        <span
          className="GetEmailForm-BackLink"
          onClick={() => setForgetPassword('uncheck')}
        >
          Back
        </span>
      </div>

      <Tost
        message={tostMessage}
        openTost={openTost}
        setOpenTost={setOpenTost}
        type={tostType}
      />
      <Loader loader={loader} setLoader={setLoader} />
    </form>
  );
}

export default GetEmailForm;
