import React, { useContext, useState } from 'react';
import Heading from '../components/Heading';
import Button from '../components/Button';
import { ScrollContext } from '../context/ScrollContext';
import useAuth from '../hooks/useAuth';
import Tost from '../components/Tost/Tost';
import Loader from '../components/Loader/Loader';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import NavBar from '../components/NavBar';
import LogoBar from '../components/LogoBar';
import { validatePublish } from '../Utils/FormValidation';
import './PublishDataset.css'; // Import the CSS file

function PublishDataset({ navigate }) {
  const { skipref } = useContext(ScrollContext);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [description, setDescription] = useState('');

  const [tostMessage, setTostMessage] = useState('Oops Something went wrong');
  const [tostType, setTostType] = useState('error');
  const [openT, setOpenT] = useState(false);
  const [loader, setLoader] = useState(false);

  const { auth } = useAuth();
  const axios = useAxiosPrivate();

  // Function to handle checkbox change
  const handleCheckboxChange = (option) => {
    // Check if the option is already selected
    const selectedIndex = selectedOptions.indexOf(option);

    if (selectedIndex === -1) {
      // If not selected, add it to the array
      setSelectedOptions([...selectedOptions, option]);
    } else {
      // If selected, remove it from the array
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    }
  };

  const handlePublishData = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const { status, msg } = validatePublish(description);
      if (!status) {
        setLoader(false);
        setOpenT(true);
        setTostMessage(msg);
        setTostType('error');
        return;
      }
      const requestData = {
        data_description: description,
        annotation: selectedOptions.includes('Annotation') ? true : false,
        pre_process: selectedOptions.includes('Pre-process') ? true : false,
        email: auth.email,
      };
      const response = await axios.post(`/user/publish-data`, requestData);
      // console.log(response.data);
      setLoader(false);
      setTimeout(() => {
        setOpenT(false);
      }, 2000);
      setOpenT(true);
      setTostMessage(response.data.status);
      setTostType('success');

      setDescription('');
      setSelectedOptions([]);
    } catch (e) {
      // console.log(e);
      setLoader(false);
      setTimeout(() => {
        setOpenT(false);
      }, 2000);
      if (e.code === 'ERR_NETWORK') {
        setTostMessage(e.message);
      } else {
        setTostMessage(e.response.data.detail);
      }
      setTostType('error');
      setOpenT(true);
    }
  };

  return (
    <>
      <NavBar navigate={navigate} type={'dataEcho'} />
      <LogoBar navigate={navigate} type={'dataEcho'} />
      <form autoComplete="off" onSubmit={handlePublishData}>
        <div className="PublishDataset-container" ref={skipref}>
          <div className="PublishDataset-content">
            <div>
              <Heading text={'Publish Dataset'} />
              <span className="PublishDataset-description">
                The application facilitates seamless dataset publication,
                empowering researchers to share their annotated data with the
                wider scientific community. Through intuitive upload mechanisms
                and comprehensive documentation tools, researchers can
                contribute their datasets while ensuring data integrity and
                transparency. This feature enhances the visibility of research
                efforts and fosters collaboration among researchers.
              </span>
            </div>

            <div className="PublishDataset-checkbox-wrapper">
              <Heading text={'Data Description'} />
              <textarea
                className="PublishDataset-textarea"
                placeholder="Type here data description which you want to publish with data echo..."
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                required
              />
            </div>

            <div className="PublishDataset-checkbox-wrapper">
              <div className="PublishDataset-checkbox-container">
                <label className="PublishDataset-checkbox-label">
                  <input
                    autoComplete="off"
                    className="PublishDataset-checkbox-input"
                    type="checkbox"
                    value="Annotation"
                    checked={selectedOptions.includes('Annotation')}
                    onChange={() => handleCheckboxChange('Annotation')}
                  />
                  Annotation
                </label>
                <br />
                <label className="PublishDataset-checkbox-label">
                  <input
                    autoComplete="off"
                    className="PublishDataset-checkbox-input"
                    type="checkbox"
                    value="Pre-process"
                    checked={selectedOptions.includes('Pre-process')}
                    onChange={() => handleCheckboxChange('Pre-process')}
                  />
                  Pre-process
                </label>
              </div>
            </div>

            <div className="PublishDataset-button-container">
              <Button text={'Send Request'} />
            </div>
          </div>
          <Tost
            message={tostMessage}
            openTost={openT}
            setOpenTost={setOpenT}
            type={tostType}
          />
          <Loader loader={loader} setLoader={setLoader} />
        </div>
      </form>
    </>
  );
}

export default PublishDataset;
