import React, { useState, useContext } from "react";
import Button from "./Button";
import Modal from "./Modal";
import axios from "axios";
import LoginModal from "./LoginModal";
import useAuth from "../hooks/useAuth";
import { ScrollContext } from "../context/ScrollContext";
import { ModalContext } from "../context/ModalContext";
import Tost from "./Tost/Tost";
import SignupModal from "./SignupModal";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import "./Styles/NavBar.css"; // Import the CSS file

function NavBar({ navigate, type }) {
  const axios = useAxiosPrivate();
  const { scrollIntoDiv, skipref } = useContext(ScrollContext);

  const [open, setOpen] = useState(false);
  const [openSignup, setOpenSignup] = useState(false);
  const [openTost1, setOpenTost1] = useState(false);
  const [openTost, setOpenTost] = useState(false);
  const [tostMessage, setTostMessage] = useState("Oops Something Went Wrong!!");
  const [tostType, setTostType] = useState("error");
  const { auth, setAuth } = useAuth();
  const [registration, setRegistration] = useState({
    full_name: "",
    email: "",
    mobile_no: "",
    institution: "",
    institution_email: "",
    department: "",
    designation: "",
    password: "",
  });

  const { openLogin, setOpenLogin } = useContext(ModalContext);

  const handleLogout = async () => {
    try {
      const response = await axios.post("/auth/logout");
      const status = response.data.status;
      setAuth({});
      setTimeout(() => {
        setOpenTost1(false);
      }, 2000);
      setOpenTost1(true);
      setTostMessage(status);
      setTostType("success");
    } catch (e) {
      console.error(e);
      setTimeout(() => {
        setOpenTost1(false);
      }, 2000);
      setOpenTost1(true);
      setTostMessage("Oops something went wrong!!");
      setTostType("error");
    }
  };

  if (type === "dataEcho") {
    return (
      <div className="NavBar-styled-top-div">
        <Tost
          message={tostMessage}
          type={tostType}
          openTost={openTost1}
          setOpenTost={setOpenTost1}
        />
        <div className="NavBar-styled-div">
          <div className="NavBar-nav-links">
            <span className="NavBar-nav-link" onClick={() => navigate("/")}>
              Home
            </span>
            <span
              className="NavBar-nav-link"
              onClick={() => {
                navigate("/dataecho");
                scrollIntoDiv();
              }}
            >
              Services
            </span>
            <span
              className="NavBar-nav-link"
              onClick={() => {
                navigate("/projects");
              }}
            >
              Projects
            </span>
            {auth?.token ? (
              <Button text={"Logout"} onClick={handleLogout} type={"nav"} />
            ) : (
              <Button
                text={"Login"}
                onClick={() => setOpenLogin(true)}
                type={"nav"}
              />
            )}
          </div>

          <LoginModal
            open={openLogin}
            setOpen={setOpenLogin}
            openSignup={openSignup}
            setOpenSignup={setOpenSignup}
            setOpenTost={setOpenTost}
            openTost={openTost}
          />

          <SignupModal
            open={openLogin}
            setOpen={setOpenLogin}
            openSignup={openSignup}
            setOpenSignup={setOpenSignup}
            setOpenTost={setOpenTost}
            openTost={openTost}
          />
        </div>
      </div>
    );
  } else if (type === "landing") {
    return (
      <div className="NavBar-styled-top-div">
        <Tost
          message={tostMessage}
          type={tostType}
          openTost={openTost1}
          setOpenTost={setOpenTost1}
        />
        <div className="NavBar-styled-div">
          <div className="NavBar-nav-links">
            <span className="NavBar-nav-link" onClick={() => navigate("/")}>
              Home
            </span>
            <span
              className="NavBar-nav-link"
              onClick={() => {
                navigate("/dataecho");
              }}
            >
              Data Echo
            </span>
            <span
              className="NavBar-nav-link"
              onClick={() => {
                navigate("/projects");
              }}
            >
              Projects
            </span>
            <span
              className="NavBar-nav-link"
              onClick={() => navigate("/researchDetails")}
            >
              Research
            </span>
            <span
              className="NavBar-nav-link"
              onClick={() => navigate("/eventsDetails")}
            >
              Events
            </span>
          </div>

          <LoginModal
            open={openLogin}
            setOpen={setOpenLogin}
            openSignup={openSignup}
            setOpenSignup={setOpenSignup}
            setOpenTost={setOpenTost}
            openTost={openTost}
          />

          <SignupModal
            open={openLogin}
            setOpen={setOpenLogin}
            openSignup={openSignup}
            setOpenSignup={setOpenSignup}
            setOpenTost={setOpenTost}
            openTost={openTost}
          />
        </div>
      </div>
    );
  }
}

export default NavBar;
