import React, { useEffect } from "react";
import About from "../components/About/About";
import NavBar from "../components/NavBar";
import LogoBar from "../components/LogoBar";
import "./AboutUs.css";

function AboutUs({ navigate }) {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  return (
    <>
      <NavBar navigate={navigate} type={"landing"} />
      <LogoBar navigate={navigate} type={"landing"} />
      <div>
        <div className="aboutus-styled-div">
          <About />
        </div>
      </div>
    </>
  );
}

export default AboutUs;
