import React, { useContext, useState } from 'react';
import Heading from '../components/Heading';
import Button from '../components/Button';
import { ScrollContext } from '../context/ScrollContext';
import NavBar from '../components/NavBar';
import LogoBar from '../components/LogoBar';
import emailjs from 'emailjs-com';
import { validateConnectUs } from '../Utils/FormValidation';
import useAuth from '../hooks/useAuth';
import Loader from '../components/Loader/Loader';
import Tost from '../components/Tost/Tost';
import './DataConsultancy.css';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import ReCAPTCHA from 'react-google-recaptcha';

function DataConsultancy({ navigate }) {
  const axios = useAxiosPrivate();
  const { skipref } = useContext(ScrollContext);
  const [templateId] = useState(process.env.REACT_APP_TEMPLATE_ID);
  const { auth } = useAuth();
  const [loader, setLoader] = useState(false);
  const [openTost, setOpenTost] = useState(false);
  const [tostType, setTostType] = useState('error');
  const [tostMessage, setTostMessage] = useState('Oops something went wrong');
  const [captchaStr, setCaptchaStr] = useState('');

  const [formData, setFormData] = useState({
    from_name: auth.fullName,
    from_email: auth.email,
    message: '',
    to_name: 'team dataecho',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoader(true);

    try {
      const { status, msg } = validateConnectUs(formData);
      if (!status) {
        setLoader(false);
        setOpenTost(true);
        setTostMessage(msg);
        setTostType('error');
        return;
      }

      const formDataUpdated = { ...formData, captcha: captchaStr };

      const response = await axios.post('/user/contact-us', formDataUpdated);
      setFormData({
        from_name: '',
        from_email: '',
        message: '',
        to_name: 'DataEcho Team',
      });
      setLoader(false);

      if (response.data.status === 'OK') {
        setTostMessage(response.data.message);
        setTostType('success');
        setTimeout(() => {
          setOpenTost(false);
        }, 2000);
      } else if (response.data.status === 'error') {
        setTostMessage('Invalid Message, Please try again');
        setTostType('error');
      }

      setOpenTost(true);
    } catch (e) {
      setFormData({
        from_name: '',
        from_email: '',
        message: '',
        to_name: 'DataEcho Team',
      });
      setLoader(false);
      setTimeout(() => {
        setOpenTost(false);
      }, 2000);
      if (e.code === 'ERR_NETWORK') {
        setTostMessage(e.message);
      } else if (e.code === 'ERR_BAD_REQUEST') {
        setTostMessage('Invalid message');
      } else {
        setTostMessage(e.response.data.detail);
      }
      setTostType('error');
      setOpenTost(true);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCaptchChange = (value) => {
    setCaptchaStr(value);
  };

  return (
    <>
      <NavBar navigate={navigate} type={'dataEcho'} />
      <LogoBar navigate={navigate} type={'dataEcho'} />
      <div className="DataConsultancy-container">
        <div className="DataConsultancy-form-wrapper" ref={skipref}>
          <Heading
            text={
              'Data Consultation: Expertise at your fingertips from consultation to data collection mastery'
            }
          />
          <span className="DataConsultancy-styled-span">
            Recognizing the importance of experimental design and data
            collection methodologies, our application offers consultation
            services to assist researchers in setting up experimental paradigms
            and optimizing data collection processes. Whether it's designing
            experimental protocols, selecting appropriate sensors, or
            implementing data collection techniques, our team of experts
            provides personalized guidance to researchers, ensuring the quality
            and reliability of collected data for their studies.
          </span>
          <Heading text={'Personalized Guidance'} />
          <span className="DataConsultancy-styled-span">
            Our experts are here to guide you through every step of your
            research process, from consultation to the mastery of data
            collection techniques.
          </span>
          <Heading text={'Optimized Data Collection'} />
          <span className="DataConsultancy-styled-span">
            Utilize our expertise to select the most appropriate sensors and
            techniques for your research, ensuring the highest quality of data.
          </span>
          <form
            autoComplete="off"
            className="DataConsultancy-form"
            onSubmit={handleSubmit}
          >
            <Heading text={'Connect Us'} />
            <input
              autoComplete="off"
              aria-autocomplete="none"
              className="DataConsultancy-input"
              placeholder="Name"
              name="from_name"
              value={auth.fullName}
              hidden
              required
            />
            <input
              autoComplete="off"
              className="DataConsultancy-input"
              placeholder="Name"
              name="to_name"
              value={'Team Data Echo'}
              // onChange={handleChange}
              hidden
            />
            <input
              autoComplete="off"
              className="DataConsultancy-input"
              placeholder="Email"
              name="from_email"
              value={auth.email}
              hidden
              // onChange={handleChange}
            />
            <textarea
              autoComplete="off"
              className="DataConsultancy-textarea"
              rows={10}
              placeholder="Enter text"
              value={formData.message}
              onChange={handleChange}
              name="message"
              required
            />
            <div className="recaptcha-container">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                size="normal"
                nonce={document
                  .querySelector('meta[name="csp-nonce"]')
                  .getAttribute('content')}
                onChange={handleCaptchChange}
              />
            </div>
            <div className="DataConsultancy-button-wrapper">
              <Button text={'Send'} />
            </div>

            <Loader loader={loader} setLoader={setLoader} />
            <Tost
              openTost={openTost}
              setOpenTost={setOpenTost}
              message={tostMessage}
              type={tostType}
            />
          </form>
        </div>
      </div>
    </>
  );
}

export default DataConsultancy;
