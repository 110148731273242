import React, { useContext } from "react";
import Activities from "../components/Activities/Activities";
import { ScrollContext } from "../context/ScrollContext";
import NavBar from "../components/NavBar";
import LogoBar from "../components/LogoBar";
function EventsDetails({ navigate }) {
  const { skipref } = useContext(ScrollContext);
  return (
    <>
      <NavBar navigate={navigate} type={"landing"} />
      <LogoBar navigate={navigate} type={"landing"} />
      <div ref={skipref}>
        <Activities />
      </div>
    </>
  );
}

export default EventsDetails;
