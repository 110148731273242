import React from "react";
import "./Styles/SimpleCard.css"; // Import the CSS file

function SimpleCard({ margin, image, text }) {
  return (
    <div className="SimpleCard-StyledCard">
      <img className="SimpleCard-CardImage" src={image} />
      <div className="SimpleCard-CardText">
        <span>{text}</span>
      </div>
    </div>
  );
}

export default SimpleCard;
