import React, { useEffect, useState } from "react";
import "./Styles/SearchBarLg.css"; // Import the CSS file
import { IoSearch } from "react-icons/io5";

function SearchBarLg({ datasetList, setFilteredData }) {
  const [searchValue, setSearchValue] = useState("");

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Handle Enter key press if needed
    }
  };

  useEffect(() => {
    setFilteredData(datasetList);
  }, [datasetList, setFilteredData]);

  const handleSearch = (event) => {
    const newQuery = event.target.value;
    setSearchValue(newQuery);
    const filtered = datasetList.filter((item) =>
      item.text.toLowerCase().includes(newQuery.toLowerCase())
    );
    setFilteredData(filtered);
  };

  return (
    <div className="SearchBarLg-container">
      <IoSearch className="SearchBarLg-icon" color="#757575" size="1.5rem" />
      <input
        className="SearchBarLg-input"
        type="text"
        placeholder="Type here to search dataset e.g. 'eeg'"
        onKeyDown={handleKeyPress}
        onChange={handleSearch}
        value={searchValue}
      />
    </div>
  );
}

export default SearchBarLg;
