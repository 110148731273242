import React from "react";
import "./Styles/Card.css"; // Import the CSS file

function Card({ image, heading, content }) {
  return (
    <div className="Card-styled-div">
      <div className="my-hover">
        <img className="Card-image" src={image} alt={heading} />
        <div className="Card-content-div">
          <h4 className="Card-heading">{heading}</h4>
          <h6 className="Card-sub-heading">{content}</h6>
        </div>
      </div>
    </div>
  );
}

export default Card;
