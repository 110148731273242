import React, { useState } from "react";
import { ModalContext } from "./ModalContext";

function ModalState({ children }) {
  const [openLogin, setOpenLogin] = useState(false);
  const [openSignup, setOpenSignup] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  return (
    <ModalContext.Provider
      value={{
        openLogin,
        setOpenLogin,
        openSignup,
        setOpenSignup,
        openFilter,
        setOpenFilter,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}

export default ModalState;
