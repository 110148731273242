import React from "react";
import MEDTECH from "../../images/med-tech.JPG";
import B4U from "../../images/b4u.png";
import AKAM from "../../images/akam-2022.jpg";
import MEETING from "../../images/image-008.jpg";
import ActivityCard from "./ActivityCard";
import "./ActivitiesComponent.css"; // Import the CSS file

function ActivityCards({ setSelectedActivity }) {
  const activities = [
    {
      title: "MED-TECH-MEET",
      date: "2023 March 16",
      image: MEDTECH,
      description: `For proliferation and knowledge sharing in this advanced domain, 
      Neuro-cognitive AI and XR group, C-DAC Delhi conducted a one day “MED-TECH-MEET" on 
      16 March, 2023 at C-DAC Delhi.`,
      navigateTo: "med-tech",
    },
    {
      title: "B4U",
      date: "2023 February 16",
      image: B4U,
      description: `A webinar was organized in online mode on February 16 and 17, 2023. 
      The aim of the webinar was to integrate knowledge to better understand and address 
      cognitive, psychological or physiological problems related to bio-brain-behavioural 
      aspects. `,
      navigateTo: "b4u",
    },
    {
      title: "Grand Challenge Contest",
      date: "2022 March",
      image: AKAM,
      description: `Azadi Ka Amrit Mahotsav (India@75) is an initiative of the Government 
      of India to celebrate and commemorate 75 years of progressive India and the glorious 
      history of it's people, culture and achievements.`,
      navigateTo: "akam",
    },
    {
      title: "A Confluence of Minds at the Scientific Meeting",
      date: "2023 June 6",
      image: MEETING,
      description: `C-DAC, Delhi centre was abuzz with excitement as it welcomed 
      distinguished scientists from Central Forensic Science Laboratory (CFSL), CBI, 
      New Delhi for a visit aimed at fostering collaboration and technological advancements 
      on June 06th`,
      navigateTo: "meeting",
    },
  ];
  return (
    <div className="ActivityCardsComponent-CardsContainer">
      {activities.map((item, index) => (
        <ActivityCard
          key={index}
          image={item.image}
          title={item.title}
          date={item.date}
          description={item.description}
          navigateTo={item.navigateTo}
          setSelectedActivity={setSelectedActivity}
        />
      ))}
    </div>
  );
}

export default ActivityCards;
