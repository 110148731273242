import React, { useState } from 'react';
import { GrPowerReset } from 'react-icons/gr';
import { MdKeyboardArrowDown } from 'react-icons/md';
import FilterModal from './FilterModal';
import './Styles/FilterData.css'; // Import the CSS file

function FilterData({ setType }) {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <FilterModal isOpen={open} handleClose={handleClose} setType={setType} />
      <div className="FilterData-Top-Container">
        <div className="FilterData-container">
          <div className="FilterData-item" onClick={handleOpen}>
            <div>Filter By</div>
            <div>
              <MdKeyboardArrowDown />
            </div>
          </div>
          <div className="FilterData-item1" onClick={() => setType('modality')}>
            <div>
              <GrPowerReset color="red" />
            </div>
            <div>Reset Filter</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FilterData;
