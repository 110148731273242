import React, { useState } from 'react';
import Button from '../Button';
import Tost from '../Tost/Tost';
import Loader from '../Loader/Loader';
import MyCarousel from '../MyCarousel';
import OtpInput from 'react-otp-input';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate } from 'react-router-dom';
import { validateOTP } from '../../Utils/FormValidation';
import './ForgetPassword.css'; // Import the CSS file
import ReCAPTCHA from 'react-google-recaptcha';

function GetOTPForm({
  setOpen,
  setOpenSignup,
  openTost,
  setOpenTost,
  setForgetPassword,
  setResetToken,
}) {
  // const [otp, setOTP] = useState('');
  const [loader, setLoader] = useState(false);
  const [tostMessage, setTostMessage] = useState('Oops something went wrong');
  const [tostType, setTostType] = useState('error');

  const axios = useAxiosPrivate();
  const navigate = useNavigate();

  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [captchaValue, setCaptchaValue] = useState('');

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    // Focus on next input
    if (element.value && element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index]) {
      if (e.target.previousSibling) {
        e.target.previousSibling.focus();
      }
    }
  };

  const handleOTPSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);
    try {
      const { status, msg } = validateOTP(otp.join(''));

      if (!status) {
        setLoader(false);
        setOpenTost(true);
        setTostMessage(msg);
        setTostType('error');
        return;
      }

      // console.log(otp.join(''));
      const response = await axios.post('/auth/verify/otp', {
        otp: otp.join(''),
        captcha: captchaValue,
      });
      // console.log(response.data);

      if (response.data.hasOwnProperty('error_reference')) {
        throw new Error('Invalid OTP');
      }

      setTostMessage(response.data.status);
      setTostType('success');
      setTimeout(() => {
        setForgetPassword('change');
        setOpenTost(false);
      }, 2000);

      setResetToken(response.data.reset_token);
      setLoader(false);
    } catch (e) {
      setLoader(false);
      setOpenTost(true);
      // console.log('In Catch');
      // console.log(e.message);
      setTostMessage(e.message);
      setTostType('error');
      setTimeout(() => {
        setOpenTost(false);
      }, 2000);
    }
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  return (
    <form
      autoComplete="off"
      className="GetOTPForm-FormWrapper"
      onSubmit={handleOTPSubmit}
    >
      <div className="GetOTPForm-CarouselWrapper">
        <MyCarousel />
      </div>
      <div className="GetOTPForm-FormContainer">
        <h1 className="GetOTPForm-Title">Verify OTP</h1>

        <div className="otp-input">
          {otp.map((data, index) => {
            return (
              <input
                autoComplete="off"
                aria-autocomplete="none"
                className="otp-field"
                type="text"
                name="otp"
                maxLength="1"
                key={index}
                value={data}
                onChange={(e) => handleChange(e.target, index)}
                onFocus={(e) => e.target.select()}
                onKeyDown={(e) => handleKeyDown(e, index)}
              />
            );
          })}
        </div>

        <div className="re-captcha-wrapper">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            size="normal"
            onChange={handleCaptchaChange}
          />
        </div>

        <div className="GetOTPForm-FlexContainer"></div>

        <Button text={'Submit'} />
        <span
          className="GetOTPForm-BackLink"
          onClick={() => setForgetPassword('checked')}
        >
          Back
        </span>
      </div>

      <Tost
        message={tostMessage}
        openTost={openTost}
        setOpenTost={setOpenTost}
        type={tostType}
      />
      <Loader loader={loader} setLoader={setLoader} />
    </form>
  );
}

export default GetOTPForm;
