import React from "react";
import { newsList } from "./NewsList";
import NewsCard from "./NewsCard";
import Heading from "../Heading";
import "./NewsComponent.css"; // Import the CSS file

function News() {
  return (
    <div className="News-Container">
      <div className="News-ContentWrapper">
        <Heading text={"News"} />
        <div className="News-NewsListWrapper">
          {newsList.map((item, index) => (
            <NewsCard
              key={index} // Added key prop for NewsCard list
              heading={item.heading}
              headline={item.news}
              image={item.image}
              date={item.date}
              siteLink={item.siteLink}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default News;
