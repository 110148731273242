import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import NavBar from "../components/NavBar";
import LogoBar from "../components/LogoBar";
import Heading from "../components/Heading";
import "./Projects.css"; // Import the CSS file

function Projects({ navigate }) {
  const axios = useAxiosPrivate();

  const [projects, setProjects] = useState([]);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await axios.get("/user/projects");
      // console.log(response.data);
      setProjects(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  function splitString(inputString) {
    return inputString.split("#");
  }
  return (
    <>
      <NavBar navigate={navigate} type={"landing"} />
      <LogoBar navigate={navigate} type={"landing"} />
      <div className="Projects-container">
        <div className="Projects-content">
          <div class="accordion" id={`accordionExample`}>
            {projects.map((item, index) => (
              <>
                <div class="accordion-item" id={index}>
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={"#collapseOne" + index}
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <Heading text={item.title} />
                    </button>
                  </h2>
                  <div
                    id={"collapseOne" + index}
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <strong>{item.desc}</strong>

                      <Heading text={"Use Cases"} classN="mt-3" />
                      <ul className="Projects-unordered-list">
                        {splitString(item.use_case).map((item1, index1) => (
                          <li key={index1}>{item1}</li>
                        ))}
                      </ul>
                      <Heading text={"Salient Features"} />
                      <ul className="Projects-unordered-list">
                        {splitString(item.salient_features).map(
                          (item2, index2) => (
                            <li key={index2}>{item2}</li>
                          )
                        )}
                      </ul>

                      <h4 className="Projects-investigator-details">
                        Chief Investigator Details
                      </h4>
                      <h4>{item.chief_investigator_detail}</h4>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Projects;
