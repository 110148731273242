import React from "react";
import "./Loader.css"; // Import the CSS file

function Loader({ loader, setLoader }) {
  return (
    <div className={`custom-backdrop ${loader ? "show" : ""}`}>
      <div className="Loader-LoaderContainer">
        <div
          className="spinner-border  Loader-StyledCircularProgress"
          role="status"
        />
        <div>Loading...</div>
      </div>
    </div>
  );
}

export default Loader;
