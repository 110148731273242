import React, { useState, useEffect } from "react";
import "./Tost.css";

function Tost({ message, type, openTost, setOpenTost }) {
  const [msgType, setMessageType] = useState("");
  useEffect(() => {
    if (type === "error") {
      setMessageType("danger");
    } else {
      setMessageType(type);
    }
  }, [type]);

  useEffect(() => {
    if (openTost) {
      setTimeout(() => setOpenTost(false), 5000); // Automatically close after 5 seconds
    }
  }, [openTost, setOpenTost]);
  return (
    <>
      <div
        className={`toast ${
          openTost ? "show" : ""
        }  position-fixed bottom-0 end-0  border-0`}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div className={`alert Tost-container alert-${msgType}`} role="alert">
          <div>{message}</div>
        </div>
      </div>
    </>
  );
}

export default Tost;
