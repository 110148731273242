import React, { useState, useEffect } from 'react';
import Home from './pages/Home';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import ExploreData from './pages/ExploreData';
import { useNavigate } from 'react-router-dom';
import AuthState from './context/AuthState';
import AccessbilityBar from './components/AccessbilityBar';
import Header from './components/Header';
import DataDetails from './pages/DataDetails';
import ResearchDetails from './pages/ResearchDetails';
import AboutUs from './pages/AboutUs';
import EventsDetails from './pages/EventsDetails';
import NewsDetails from './pages/NewsDetails';
import UseCasesDetails from './pages/UseCasesDetails';
import CustomizeOrder from './pages/CustomizeOrder';
import DataConsultancy from './pages/DataConsultancy';
import PublishDataset from './pages/PublishDataset';
import LandingPage from './pages/LandingPage';
import RequireAuth from './components/ProtectedRoutes/RequireAuth';
import ModalState from './context/ModalState';
import ScrollState from './context/ScrollState';
import Bmi from './pages/Bmi';
import Mnp from './pages/Mnp';
import Projects from './pages/Projects';
import PersistLogin from './components/Persist/PersistLogin';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();

  useEffect(() => {
    const nonce = document
      .querySelector('meta[name="csp-nonce"]')
      .getAttribute('content');

    const styleElement = document.createElement('style');
    styleElement.nonce = nonce;
    styleElement.innerHTML = `
      .apexcharts-tooltip {
        background: #fff;
        border-radius: 5px;
        padding: 10px;
      }
    `;

    document.head.appendChild(styleElement);
  }, []);

  return (
    <>
      <AuthState>
        <ModalState>
          <ScrollState>
            <AccessbilityBar navigate={navigate} params={state} />
            {/* <NavBar navigate={navigate} type={"landing"} />
          <LogoBar navigate={navigate} type={"landing"} /> */}
            <Routes>
              {/* Public Routes */}
              <Route element={<PersistLogin />}>
                <Route path="/" element={<LandingPage navigate={navigate} />} />
                <Route
                  path="/dataecho"
                  element={<Home navigate={navigate} />}
                />
                <Route
                  path="/researchDetails"
                  element={<ResearchDetails navigate={navigate} />}
                />
                <Route
                  path="/aboutUs"
                  element={<AboutUs navigate={navigate} />}
                />
                <Route
                  path="/newsDetails"
                  element={<NewsDetails navigate={navigate} />}
                />
                <Route
                  path="/useCasesDetails"
                  element={<UseCasesDetails navigate={navigate} />}
                />
                <Route
                  path="/eventsDetails"
                  element={<EventsDetails navigate={navigate} />}
                />

                <Route path="/bmi" element={<Bmi navigate={navigate} />} />
                <Route path="/mnp" element={<Mnp navigate={navigate} />} />
                <Route
                  path="/projects"
                  element={<Projects navigate={navigate} />}
                />
                <Route
                  path="/privacy"
                  element={<PrivacyPolicy navigate={navigate} />}
                />
                <Route path="*" element={<Navigate to="/" replace />} />

                {/* Users Routes */}

                <Route element={<RequireAuth allowedRole={'USER'} />}>
                  <Route
                    path="/exploreData"
                    element={<ExploreData navigate={navigate} />}
                  />
                  <Route
                    path="/dataDetails"
                    element={<DataDetails navigate={navigate} params={state} />}
                  />
                  <Route
                    path="/customizeOrder"
                    element={<CustomizeOrder navigate={navigate} />}
                  />
                  <Route
                    path="/publishDataset"
                    element={<PublishDataset navigate={navigate} />}
                  />
                  <Route
                    path="/dataConsultancy"
                    element={<DataConsultancy navigate={navigate} />}
                  />
                </Route>
              </Route>
            </Routes>
            {pathname === '/' ? (
              <Header navigate={navigate} type={'landing'} />
            ) : (
              <Header navigate={navigate} type={'dataEcho'} />
            )}
          </ScrollState>
        </ModalState>
      </AuthState>
    </>
  );
}

export default App;
