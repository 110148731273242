import React, { useState, useEffect, useContext } from 'react';
import SearchBarLg from '../components/SearchBarLg';
import FilterData from '../components/FilterData';
import Heading from '../components/Heading';
import SimpleCard from '../components/SimpleCard';
import AvailableDataBarChart from '../components/Charts/AvailableDataBarChart';
import EEG from '../images/img6_new.png';
import ECG from '../images/img12_new.png';
import EOG from '../images/img10_new.png';
import GSR from '../images/gsr.png';
import EYE_GAZE from '../images/img8_new.png';
import AV from '../images/audio_vid1.png';
import SubjectCarousel from '../components/SubjectCarousel';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { ScrollContext } from '../context/ScrollContext';
import NavBar from '../components/NavBar';
import LogoBar from '../components/LogoBar';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.min.css';
import './ExploreData.css';

const DatasetList = [
  {
    text: 'Stress EEG',
    image: EEG,
    navigate_to: '/dataDetails',
    type: 'eeg',
    click: '#',
  },
  {
    text: 'Stress EOG',
    image: EOG,
    navigate_to: '/dataDetails',
    type: 'eog',
    click: '#',
  },
  {
    text: 'Stress ECG',
    image: ECG,
    navigate_to: '/dataDetails',
    type: 'ecg',
    click: '#',
  },
  {
    text: 'Stress GSR',
    image: GSR,
    navigate_to: '/dataDetails',
    type: 'gsr',
    click: '#',
  },
  {
    text: 'Stress EYE GAZE',
    image: EYE_GAZE,
    navigate_to: '/dataDetails',
    type: 'eye_gaze',
    click: '#',
  },
  {
    text: 'Audio and Video',
    image: AV,
    navigate_to: '#',
    type: 'Audio and Video',
    click: '#',
  },
];

function ExploreData({ navigate }) {
  const { skipref } = useContext(ScrollContext);

  const [series, setSeries] = useState([30, 40, 45, 50, 49, 60, 70, 91]);
  const [labels, setLabels] = useState([
    'EEG',
    'EOG',
    'ECG',
    'GSR',
    'EYE_GAZE',
    'audio',
    'video',
  ]);
  const [type, setType] = useState('modality');
  const [filteredData, setFilteredData] = useState([]);

  const axios = useAxiosPrivate();

  useEffect(() => {
    fetchData('modality');
  }, [type]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`/access/${type}-count`);
      const data = response.data;
      // console.log(data);
      let tempSeries = [];
      let tempLabels = [];

      for (let key in data) {
        tempLabels.push(key);
        tempSeries.push(data[key]);
      }

      setLabels(tempLabels);
      setSeries(tempSeries);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <NavBar navigate={navigate} type={'dataEcho'} />
      <LogoBar navigate={navigate} type={'dataEcho'} />
      <div ref={skipref}>
        <div className="ExploreData-container">
          <div className="ExploreData-filter-wrapper">
            <FilterData setType={setType} setFilteredData={setFilteredData} />
          </div>
        </div>
        <div className="ExploreData-content-wrapper">
          <div className="ExploreData-main-content">
            <Heading text={'Available Data'} />
            <div className="ExploreData-chart-and-carousel-wrapper">
              <div className="ExploreData-chart-wrapper1">
                <div className="ExploreData-chart-wrapper">
                  <AvailableDataBarChart seriesData={series} labels={labels} />
                </div>
                <div className="ExploreData-chart-radius">
                  <SubjectCarousel />
                </div>
              </div>
            </div>

            <Heading text={'Have a look into sample data'} />
            <div className="ExploreData-search-bar-wrapper">
              <div className="ExploreData-styled-width">
                <SearchBarLg
                  datasetList={DatasetList}
                  setFilteredData={setFilteredData}
                />
              </div>
            </div>

            <div className="ExploreData-card-wrapper">
              {filteredData.map((item, index) =>
                item.text === 'Audio and Video' ? (
                  <div
                    onClick={() => {
                      Swal.fire({
                        title: 'Privacy',
                        icon: 'warning',
                        text: 'Audio and Video data is not being shown due to privacy concern',
                        confirmButtonColor: 'var(--primary-color)',
                        didOpen: () => {
                          const script = document.createElement('script');
                          script.nonce = window.__nonce__;
                          document.head.appendChild(script);
                        },
                      });
                      navigate(item.navigate_to, {
                        state: { type: item.type },
                      });
                    }}
                    key={index}
                  >
                    <SimpleCard
                      margin={25}
                      image={item.image}
                      text={item.text}
                    />
                  </div>
                ) : (
                  <div
                    onClick={() =>
                      navigate(item.navigate_to, { state: { type: item.type } })
                    }
                    key={index}
                  >
                    <SimpleCard
                      margin={25}
                      image={item.image}
                      text={item.text}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExploreData;
