import React from 'react';
import './Styles/Modal.css'; // Import the CSS file
import { RxCross2 } from 'react-icons/rx';

const Modal = ({ isOpen, onClose, children, width, handleClose }) => {
  if (!isOpen) return null;

  return (
    <>
      <div className="Modal-overlay" onClick={onClose}>
        <div className="Modal-content" style={{ width: width }}>
          <div className="Modal-closeButtonContainer">
            <RxCross2
              className="Modal-icon"
              size={'1.2rem'}
              color="grey"
              onClick={() => handleClose()}
            />
          </div>
          {children}
        </div>
      </div>
    </>
  );
};

export default Modal;
