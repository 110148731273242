import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ActivitiesComponent.css"; // Import the CSS file

function ActivityCard({
  image,
  title,
  description,
  date,
  navigateTo,
  setSelectedActivity,
}) {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <div
        className="ActivityCardComponent-Card"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => {
          setSelectedActivity(navigateTo);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      >
        <div>
          <img
            src={image}
            className="ActivityCardComponent-Image"
            alt={title}
          />
        </div>

        <div className="ActivityCardComponent-Content">
          <div className="ActivityCardComponent-Date">{date}</div>
          <div>
            <h4 className="ActivityCardComponent-Title">{title}</h4>
          </div>

          <div className="ActivityCardComponent-Description">{description}</div>
        </div>
      </div>
    </>
  );
}

export default ActivityCard;
