import React, { useState } from "react";
import { AuthContext } from "./AuthContext";

function AuthState({ children }) {
  const [auth, setAuth] = useState({
    fullName: "",
    email: "",
    role: "",
    token: "",
  });
  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthState;
