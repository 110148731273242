import React, { useState } from 'react';
import IMG1 from '../../images/image-003.jpg';
import IMG2 from '../../images/image-004.jpg';
import IMG3 from '../../images/image-008.jpg';
import MEDTECH from '../../images/med-tech.JPG';
import MEDTECH2 from '../../images/med-tech2.png';
import MEDTECH3 from '../../images/med-tech3.png';
import B4U from '../../images/b4u.png';
import AKAM from '../../images/akam-2022.jpg';
import ActivityCards from './ActivityCards';
import './ActivitiesComponent.css'; // Import the CSS file

function Activities() {
  const [selectedActivity, setSelectedActivity] = useState('med-tech');
  const [youtubeID1] = useState('ajUKFSvlSpI');
  const [youtubeID2] = useState('Z7SifPX_b7k');

  if (selectedActivity === 'meeting') {
    return (
      <>
        <div className="ActivitiesComponent-Container">
          <div className="ActivitiesComponent-Content">
            <h1>
              Exploring Frontiers: A Confluence of Minds at the Scientific
              Meeting
            </h1>
            <p className="ActivitiesComponent-Date">June 06th, 2023.</p>
            <img src={IMG3} className="ActivitiesComponent-Image" />
            <p className="ActivitiesComponent-Paragraph">
              Cognitive Behavior Analysis (CBA) is a widely recognized and
              effective psychological diagnosis approach. It focuses on
              examining the cognitive processes that underlie human behavior,
              with the goal of identifying patterns, biases, and distorted
              thinking that may contribute to harmful actions. Considering the
              need of its principles and techniques in the current Indian
              environment, it is well suited with advanced AI approached for
              many suitable problem statements like - mental health challenges,
              work related stress, education and academic pressure, stigma
              reduction, prevention, and resilience-building.
            </p>
            <p className="ActivitiesComponent-Paragraph">
              Realizing this important fact, the Neuro-Cognitive AI & XR Group,
              C-DAC, Delhi is working in the domain with the GIA support of
              MeitY. The projects are titled “A Brain Machine Interface enabled
              Assistive Communication System for special needs” and “A
              Multi-Modal Neuro-Physiological Framework for Behavior Analysis.”
              These futuristic research initiatives are being supported by
              renowned organizations across the nation.
            </p>
            <div className="ActivitiesComponent-ImageRow">
              <img src={IMG1} className="imagerow-image" />
              <img src={IMG2} className="imagerow-image" />
            </div>
            <p className="ActivitiesComponent-Paragraph">
              In this regard, C-DAC, Delhi centre was abuzz with excitement as
              it welcomed distinguished scientists from Central Forensic Science
              Laboratory (CFSL), CBI, New Delhi for a visit aimed at fostering
              collaboration and technological advancements on June 06th,
              2023.The meeting commenced with a welcome address by Shri. N. K.
              Jain, Senior Director & Centre Head, C-DAC, Delhi followed by
              address by Dr. Asha Srivastava, Director CFSL, CBI and Shri. Aman
              Kumar Yadav, Senior Scientific Officer, CFSL, Home Department, New
              Delhi.
            </p>
            <p className="ActivitiesComponent-Paragraph">
              The meeting encompassed discussion on the societal need, foreseen
              importance, gap in current available solutions and technical
              capabilities of C-DAC on ongoing projects and research activities.
              The guests appreciated the revolutionary initiatives and examined
              the projects in detail. They shared their valuable remarks. At the
              same time, possibilities of new projects were explored in the
              meeting. Dr. Srivastava assured her guidance and support in order
              to execute the project. The research team shared their insights as
              well. The event was well-received and that the participants found
              it inspiring and valuable.
            </p>
          </div>
          <div className="ActivitiesComponent-Divider"></div>
          <ActivityCards setSelectedActivity={setSelectedActivity} />
        </div>
      </>
    );
  } else if (selectedActivity === 'med-tech') {
    return (
      <>
        <div className="ActivitiesComponent-Container">
          <div className="ActivitiesComponent-Content">
            <h1>
              MED-TECH-MEET: 2023 brainstorming roundtable discussion at C-DAC
              Delhi
            </h1>
            <p className="ActivitiesComponent-Date">March 16th, 2023</p>
            <img
              src={MEDTECH}
              className="ActivitiesComponent-Image"
              width={'50%'}
            />
            <p className="ActivitiesComponent-Paragraph">
              The prevalence of mental health disorders in India is high, with
              an estimated 197.3 million people affected by mental health issues
              in 2020. Despite the growing awareness of the importance of mental
              health in India, there is still a need for greater investment in
              neuro-cognitive-technology services, increased access to care, and
              to reduce the stigma around mental illness. We are heading towards
              a future where neuro-cognitive technology could influence people's
              mental processes, could allow telepathic communication and could
              technologically increase human capabilities along with
              rehabilitation in case of any disorders.
            </p>
            <p className="ActivitiesComponent-Paragraph">
              For proliferation and knowledge sharing in this advanced domain,
              Neuro-cognitive AI and XR group, C-DAC Delhi conducted a one day
              “MED-TECH-MEET" on 16 March, 2023 at C-DAC Delhi. It was a
              much-needed session of congregation of a wide spectrum of experts
              related to mental health and related diseases. It was one of its
              own kind of brainstorming roundtable discussion with leading
              medical professionals and technologists where ideas were discussed
              with clarity and openness.
            </p>
            <div className="ActivitiesComponent-ImageRow">
              <img src={MEDTECH2} className="imagerow-image" />
              <img src={MEDTECH3} className="imagerow-image" />
            </div>
            <p className="ActivitiesComponent-Paragraph">
              The aim of the workshop was to understand need of the hour with
              futuristic aspects in the domain. An open discussion was invited
              among the experts to make aware of the current requirements for
              simple and effective mental health diagnosis and possible
              solutions. The meet led to vital discussions on the possible
              Medical-Technical research collaboration. As a key stakeholder in
              the domain, knowledge of attendees was crucial on the outcomes of
              the assessment and the way forward for planning.
            </p>
            <p className="ActivitiesComponent-Paragraph">
              Sh. N. K. Jain, Senior Director & Centre Head, C-DAC Delhi
              welcomed the speakers while putting forth the objective of the
              meet. After the welcome session on expectation and agenda plan of
              the meeting, the first session was allotted to the present medical
              experts. Here, they initiated the discussions with the challenges
              they have faced while performing their duties. They expressed
              their views on the requirements where advance technology can be
              useful. During 2nd half of the day, the technical experts
              presented their expertise and ideas to take care of requirements.
              In her opening address, Ms. Sunita Verma, Group Coordinator, MeitY
              highlighted the need of the hour with significant importance of
              the current domain along with the measures being taken care by
              MeitY in this regard.
            </p>
            <p className="ActivitiesComponent-Paragraph">
              The invited medical experts were namely Dr. Vikas Vazhayil,
              Professor, Department of Neurosurgery, NIMHANS, Bangalore; Dr. B.
              K. Bajaj, Head, Department of Neurology, VMMC, Safdarjung
              Hospital, Delhi; Dr. Jyotsna Agrawal, Department of Clinical
              Psychology, NIMHANS, Bangalore; Dr. Jyoti Goyal, Senior
              Psychiatrist, VIMHANS, Delhi; Dr. P. K. Gupta, Director, Batra
              Hospital, Delhi and Dr. R. S. Sangwan, Associate Professor,
              Department of Neurology, RML, Delhi.
            </p>
          </div>
          <div className="ActivitiesComponent-Divider"></div>
          <ActivityCards setSelectedActivity={setSelectedActivity} />
        </div>
      </>
    );
  } else if (selectedActivity === 'b4u') {
    return (
      <>
        <div className="ActivitiesComponent-Container">
          <div className="ActivitiesComponent-Content">
            <h1>Brainstorm4U: Brainstorming Session for New Research Areas</h1>
            <p className="ActivitiesComponent-Date">December 20th, 2022</p>
            <img
              src={B4U}
              className="ActivitiesComponent-Image"
              width={'50%'}
            />
            <p className="ActivitiesComponent-Paragraph">
              Recognizing the ever-evolving landscape of technology and the
              importance of interdisciplinary collaboration, C-DAC Delhi
              organized a dedicated Brainstorming Session on the 20th of
              December 2022. The session, known as Brainstorm4U, was an
              initiative to delve into uncharted territories of research and
              innovation, seeking novel solutions to contemporary challenges.
            </p>
            <p className="ActivitiesComponent-Paragraph">
              The event brought together a diverse group of experts, including
              technologists, researchers, industry professionals, and
              academicians, fostering an environment conducive to the exchange
              of ideas. The objective was to identify emerging trends and
              opportunities, as well as to brainstorm potential research
              projects that could drive technological advancements in various
              fields.
            </p>
            <div className="ActivitiesComponent-IframeRow">
              <iframe
                className="imagerow-image"
                src={`https://www.youtube.com/embed/${youtubeID1}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Brainstorm4U Session 1"
              ></iframe>
              <iframe
                className="imagerow-image"
                src={`https://www.youtube.com/embed/${youtubeID2}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Brainstorm4U Session 2"
              ></iframe>
            </div>
            <p className="ActivitiesComponent-Paragraph">
              The session was a melting pot of innovative thoughts and creative
              ideas, with participants engaging in spirited discussions and
              collaborative problem-solving. The interactive format allowed for
              the cross-pollination of ideas, leading to the conceptualization
              of groundbreaking research areas that hold the potential to shape
              the future of technology and its applications.
            </p>
          </div>
          <div className="ActivitiesComponent-Divider"></div>
          <ActivityCards setSelectedActivity={setSelectedActivity} />
        </div>
      </>
    );
  } else if (selectedActivity === 'akam') {
    return (
      <>
        <div className="ActivitiesComponent-Container">
          <div className="ActivitiesComponent-Content">
            <h1>AKAM C-DAC & G20-DIA Exhibition</h1>
            <p className="ActivitiesComponent-Date">February 23rd, 2022</p>
            <img
              src={AKAM}
              className="ActivitiesComponent-Image"
              width={'50%'}
            />
            <p className="ActivitiesComponent-Paragraph">
              As part of the Azadi Ka Amrit Mahotsav (AKAM) and the G20 Digital
              Innovation Alliance (G20-DIA) initiatives, C-DAC Delhi showcased
              its cutting-edge technologies and innovative projects at a
              high-profile exhibition held on February 23rd, 2022. The event was
              an opportunity to demonstrate C-DAC's contributions to India's
              digital transformation journey and its alignment with the global
              G20-DIA objectives.
            </p>
            <p className="ActivitiesComponent-Paragraph">
              The exhibition featured a wide array of projects, highlighting
              advancements in areas such as artificial intelligence,
              neuro-cognitive technologies, and XR (Extended Reality). It served
              as a platform for engaging with stakeholders, including industry
              leaders, government officials, and international delegates, to
              discuss and explore collaborative opportunities.
            </p>
            <p className="ActivitiesComponent-Paragraph">
              One of the focal points of the exhibition was the Neuro-Cognitive
              AI & XR group's work on brain-machine interfaces and assistive
              communication systems for individuals with special needs. The
              projects presented garnered significant interest, with visitors
              keen to learn about the potential applications and impact of these
              technologies on healthcare and beyond.
            </p>
            <p className="ActivitiesComponent-Paragraph">
              The AKAM C-DAC & G20-DIA Exhibition was not only a celebration of
              India's technological prowess but also a testament to C-DAC's
              commitment to driving innovation and addressing societal
              challenges through technology. The event concluded with a positive
              note, with many attendees expressing their admiration for the work
              being done and the future prospects of the initiatives showcased.
            </p>
          </div>
          <div className="ActivitiesComponent-Divider"></div>
          <ActivityCards setSelectedActivity={setSelectedActivity} />
        </div>
      </>
    );
  } else {
    return <ActivityCards setSelectedActivity={setSelectedActivity} />;
  }
}

export default Activities;
