import React, { useContext } from 'react';
import { FaFacebook, FaLinkedin, FaInstagram } from 'react-icons/fa';
import { ScrollContext } from '../context/ScrollContext';
import CDAC from '../images/text_logo_white.png';
import './Styles/Header.css'; // Import the CSS file

function Header({ navigate, type }) {
  const { scrollIntoDiv } = useContext(ScrollContext);

  return (
    <div className="Header-top-div">
      <div className="Header-social-links">
        <div
          className="Header-social-link"
          onClick={() => window.open('https://www.facebook.com/CDACNEWDELHI')}
        >
          <FaFacebook size={'1.5rem'} />
          <div className="Header-social-icon">Facebook</div>
        </div>
        <div
          className="Header-social-link"
          onClick={() =>
            window.open('https://www.linkedin.com/in/cdacnewdelhi/')
          }
        >
          <FaLinkedin size={'1.5rem'} />
          <div className="Header-social-icon">LinkedIn</div>
        </div>
        <div
          className="Header-social-link"
          onClick={() => window.open('https://www.instagram.com/cdacnewdelhi/')}
        >
          <FaInstagram size={'1.5rem'} />
          <div className="Header-social-icon">Instagram</div>
        </div>
        <div
          className="Header-social-link"
          onClick={() => window.open('https://cdac.in/')}
        >
          <img src={CDAC} width="25px" height={'25px'} alt="CDAC logo" />
          <div className="Header-social-icon">CDAC</div>
        </div>
      </div>

      <div className="Header-nav-container">
        {type === 'landing' ? (
          <div onClick={scrollIntoDiv}>
            <span className="Header-nav-item">About Us</span>
          </div>
        ) : (
          <span
            className="Header-nav-item"
            onClick={() => navigate('/aboutUs')}
          >
            About Us
          </span>
        )}
        <span
          className="Header-nav-item"
          onClick={() => navigate('/researchDetails')}
        >
          Research
        </span>
        <div className="Header-divider" />
        <span className="Header-nav-item" onClick={() => navigate('/privacy')}>
          Privacy Policy
        </span>
        <span
          className="Header-nav-item"
          onClick={() => navigate('/EventsDetails')}
        >
          Events
        </span>
      </div>

      <div className="Header-footer-side-container">
        <div className="Header-footer-contact">
          CDAC-DELHI Plot no. 20 FC-33 Institutional Area, Jasola, New Delhi,
          Delhi 110025
        </div>
        <div className="Header-footer-contact">Phone: 011 2987 9503</div>
        <span>
          <span>dataecho [at] cdac [dot] in</span>
        </span>
      </div>

      <div className="Header-div">
        {/* <hr className="Header-hr" /> */}
        <div className="Header-div-com">
          <span className="Header-footer-text">
            Developed by Centre for Development of Advanced Computing
          </span>
          {type === 'landing' ? (
            ''
          ) : (
            <span className="Header-footer-text">
              In Collaboration with INMAS, DRDO and Symbiosis Center for Applied
              AI, Symbiosis Institute of Technology
            </span>
          )}
          <span className="Header-footer-text">
            © 2024 Copyright CDAC-DELHI
          </span>
        </div>
      </div>
    </div>
  );
}

export default Header;
