import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import image1 from "../images/Subject1.jpeg";
import image3 from "../images/Subject3.jpeg";
import image4 from "../images/Subject4.jpeg";
import image5 from "../images/Subject5.jpg";
import image6 from "../images/Subject6.jpg";
import image7 from "../images/Subject7.jpg";
import "./Styles/MyCarousel.css"; // Import the CSS file

const MyCarousel = () => {
  return (
    <Carousel
      showThumbs={false}
      showArrows={false}
      showStatus={false}
      autoPlay
      interval={2000}
      infiniteLoop
    >
      <img className="MyCarousel-image" src={image1} alt="Subject1" />
      <img className="MyCarousel-image" src={image3} alt="Subject3" />
      <img className="MyCarousel-image" src={image4} alt="Subject4" />
      <img className="MyCarousel-image" src={image5} alt="Subject5" />
      <img className="MyCarousel-image" src={image6} alt="Subject6" />
      <img className="MyCarousel-image" src={image7} alt="Subject7" />
    </Carousel>
  );
};

export default MyCarousel;
