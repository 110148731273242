import React from "react";
import News from "../components/News/News";
import NavBar from "../components/NavBar";
import LogoBar from "../components/LogoBar";
import "./NewsDetails.css"; // Import the CSS file

function NewsDetails({ navigate }) {
  return (
    <>
      <NavBar navigate={navigate} type={"landing"} />
      <LogoBar navigate={navigate} type={"landing"} />
      <div>
        <News />
      </div>
      <div className="NewsDetails-container">
        <h2 className="NewsDetails-message">Being added more...</h2>
      </div>
    </>
  );
}

export default NewsDetails;
