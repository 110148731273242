import React from "react";
import NavBar from "../components/NavBar";
import LogoBar from "../components/LogoBar";
import Heading from "../components/Heading";
import "./Mnp.css";

function Mnp({ navigate }) {
  return (
    <>
      <NavBar navigate={navigate} type={"landing"} />
      <LogoBar navigate={navigate} type={"landing"} />
      <div className="Mnp-container">
        <div className="Mnp-content-wrapper">
          <Heading
            text={
              "A Multi-Model Neuro-Physiological Framework for Behaviour Analysis"
            }
          />
          <p className="Mnp-text">
            The aim of the proposed work is to employ a Multimodal
            Neuro-Physiological framework by insider threats detection and
            interrogation on suspect for inner cordon security purpose. Creation
            of comprehensive primary dataset for Indian population with targeted
            modalities
          </p>

          <Heading text={"Use Cases"} />
          <ul className="Mnp-list">
            <li>
              Neuro methods by EEG Brain signal analysis for Lie / abnormality /
              Malicious pattern detection.
            </li>
            <li>
              Multimodal fusion methods for stress, anomaly and suspicious
              behavior detection
            </li>
            <li>
              A dash-board as a data analytics, event-reporting and monitoring
              tool on web and mobile.
            </li>
          </ul>

          <Heading text={"Salient Features"} />
          <ul className="Mnp-list">
            <li>
              A modular architecture with the well balancing of cohesion and
              coupling will be formulated.
            </li>
            <li>
              AI based Machine Learning and Deep learning approaches for
              prediction
            </li>
            <li>
              A systematic plan for the development of a primary dataset as a
              by-product
            </li>
            <li>
              100 Sample size for Multimodal Dataset collection of mentioned
              modalities
            </li>
            <li>
              Neuro-Physiological model for the Human’s bio-signals like EEG,
              ECG and EOG.
            </li>
            <li>
              Cognitive behavioral analysis like stress observation, eye/
              gaze-tracking and postures.
            </li>
            <li>
              Signal capturing for analyze, extract features, and classify to
              detect the activities.
            </li>
            <li>
              A dashboard with mobile and web interface as a monitoring tool
            </li>
          </ul>

          <div className="Mnp-list">
            <h4>Chief Investigator Details</h4>
            <h4>
              Naveen Kumar Jain, Sr. Director & Centre Head, C-DAC Delhi ,
              nkjain@cdac.in
            </h4>
          </div>
        </div>
      </div>
    </>
  );
}

export default Mnp;
