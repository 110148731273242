import React, { useContext } from 'react';
import { useLocation, Navigate, Outlet } from 'react-router';
import useAuth from '../../hooks/useAuth';
import { ModalContext } from '../../context/ModalContext';

function RequireAuth({ allowedRole }) {
  const { auth } = useAuth();
  const location = useLocation();

  const { openLogin, setOpenLogin } = useContext(ModalContext);

  // console.log(auth.token);
  return auth.role === allowedRole ? (
    <>
      <Outlet />
      {setOpenLogin(false)}
    </>
  ) : auth?.role !== null ? (
    <>
      <Navigate to="/dataecho" state={{ from: location }} replace />
      {setOpenLogin(true)}
    </>
  ) : (
    <Navigate to="/dataecho" state={{ from: location }} replace />
  );
}

export default RequireAuth;
